import { RouteProps } from "react-router-dom";  
import { DashboardMonitoring } from "./engagement/engagement.component";
import { DashboardMap } from "./map/map.component";
import { DashboardOverview } from "./overview/overview.component";
import { DashboardRevenue } from "./revenue/revenue.component"; 

export enum DashboardRoute {
  Overview = '/dashboard/overview',
  Revenue = '/dashboard/revenue',
  Map = '/dashboard/map',
  Monitoring = '/dashboard/monitoring',
  Trips = '/dashboard/trips',
}

export const DashboardRouteMap = [
  {
    path: DashboardRoute.Overview,
    component: DashboardOverview,
  },
  {
    path: DashboardRoute.Map,
    component: DashboardMap,
  },
  {
    path: DashboardRoute.Monitoring,
    component: DashboardMonitoring,
  },
  {
    path: DashboardRoute.Revenue,
    component: DashboardRevenue,
  },  
] as RouteProps[];

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemStatus } from './items.component';

export interface GroupFilter {
  title: string;
  icon?: string;
}

export interface StatusFilter {
  title: string;
  status: ListItemStatus,
}

interface Props {
  groupFilters?: GroupFilter[],
  statusFilters?: StatusFilter[],
  activeGroupFilter?: GroupFilter,
  activeStatusFilter?: StatusFilter,
  onGroupSelect?: (group: GroupFilter) => void, 
  onStatusSelect?: (status: StatusFilter | undefined) => void, 
}

export function ListFilter({
  groupFilters, statusFilters, onGroupSelect, onStatusSelect, activeGroupFilter, activeStatusFilter
}: Props) {

  const { t } = useTranslation('main');

  return (
    <div className="ae-side-menu">
      { groupFilters && (
        <ul className="ae-main-menu">
          { groupFilters.map(groupFilter => (
            <li key={ groupFilter.title } className={ classNames({ active: groupFilter.title === activeGroupFilter?.title })}>
              <a
                href="#group"
                onClick={ () => { onGroupSelect && onGroupSelect(groupFilter); } }
              >
                { groupFilter.icon && <i className={`os-icon os-icon-${groupFilter.icon}`}></i> }
                <span>{ t(`view.groupFilters.${ groupFilter.title.toLowerCase() }`) }</span>
              </a>
            </li>
          ))}
        </ul>
      )}
      { statusFilters && (
        <div className="ae-labels">
          { statusFilters.map(statusFilter => (
            <a className={ 
              classNames(
                'ae-label',
                `ae-label-${statusFilter.status}`,
                { active: statusFilter.title === activeStatusFilter?.title },
              )}
              href="#status-filter"
              key={ statusFilter.title}
              onClick={ () => { onStatusSelect && onStatusSelect(statusFilter); } }
            >
              <span className="label-pin"></span>
              <span className="label-value">{ t(`view.statusFilters.${ statusFilter.title.toLowerCase() }`) }</span>
            </a>
          ))}
        </div>
      )}
    </div>
  )
}

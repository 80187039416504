import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";
import { Loading } from "../../components/alerts/loading.component";
import { GroupFilter, ListFilter } from "../../components/list/filter.component";
import { ListItem, ListItems } from "../../components/list/items.component";
import { List } from "../../components/list/wrapper.component";
import { View } from "../../components/view/wrapper.component";  
import { Device } from "../../entities/device.entity";
import { FormMode } from "../../enums/core.enum";
import { FormStyle } from "../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../services/api.service';  
import { SelectedItemContent } from "./content.component";
import { DevicePost } from "./post.component";

const groupFilters: GroupFilter[] = [
  { title: 'All', icon: 'phone-21' },
];

export function DeviceList() {

  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(1);
  const [device, setDevice] = useState<Device[] | undefined>(undefined);
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);

  const { t } = useTranslation(['main', 'list']);

  const refresh = useCallback(async () => {
    const group = activeGroupFilter?.title
    const { items } = await Api.get<IPaginationResponse<Device>, IPaginationOptions>(Endpoint.DEVICE_LIST, { page: 0, limit, group, search });

    if (items) {
      setDevice(items);
    }

    if (items.length > 0 && !selectedDevice) {
      setSelectedDevice(items[0]);
    }
  }, [search, limit, activeGroupFilter, selectedDevice]);

  useEffect(() => { refresh(); }, [refresh]);

  const handleScroll = useCallback(() => {
    if (pageYOffset > count * 150) {
      setLimit(limit + 20);
      setCount(count + 1);
    }
  }, [count, pageYOffset, limit])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return (() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }, [pageYOffset, handleScroll])

  const deleteService = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.DEVICE, { id: selectedDevice?.id });
    setSelectedDevice(undefined);
    refresh();
  }

  const toListItems = (device?: Device): ListItem<Device> | undefined => {
    if (!device) {
      return;
    }
    return {
      id: device.id as string,
      title: device.name,
      ref: device
    }
  }

  const addService = () => {
    setFormMode(FormMode.Adding);
  }

  const editService = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (device) {
      const currentServicesIndex = device.findIndex(device => !!selectedDevice && device.id === selectedDevice.id);
      if (currentServicesIndex + 1 < device.length && currentServicesIndex !== -1) {
        setSelectedDevice(device[currentServicesIndex + 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedDevice(undefined);
    setActiveGroupFilter(target);
  }

  const onPrev = () => {
    if (device) {
      const currentServiceIndex = device.findIndex(device => !!selectedDevice && device.id === selectedDevice.id);
      if (currentServiceIndex - 1 > -1 && currentServiceIndex !== -1) {
        setSelectedDevice(device[currentServiceIndex - 1]);
      }
    }
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  return (
    <List>
      <ListFilter
        groupFilters={groupFilters}
        activeGroupFilter={activeGroupFilter}
        onGroupSelect={selectGroup}
      />

      <ListItems
        items={device?.map(toListItems) as ListItem<Device>[]}
        activeItem={toListItems(selectedDevice)}
        onSearchChange={onSearchChange}
        showSearch={true}
        showRefresh={true}
        onRefresh={() => { setDevice(undefined); refresh(); }}
        onClick={(item: ListItem<Device>) => { setSelectedDevice(item.ref); }}
      />

      { formMode === undefined ? (
        device !== undefined ? (
          selectedDevice ? (
            <View
              showAdd={true}
              showEdit={true}
              showDelete={true}
              showNext={true}
              showPrev={true}
              onAdd={addService}
              onEdit={editService}
              onDelete={deleteService}
              onPrev={onPrev}
              onNext={onNext}
            >
              <SelectedItemContent
                selectedDevice={ selectedDevice }
              />
            </View>
          ) : (
              <div className="mx-auto py-5 text-center">
                <button onClick={addService} className="btn btn-primary text-bold">{t("list:major.addMajor")}</button>
              </div>
            )
        ) : (
            <div className="mx-auto py-5 text-center">
              <p>{t("list:major.wait")}</p>
              <Loading loading={true} />
            </div>
          )
      ) : (
          selectedDevice ? (
            <DevicePost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              entityId={selectedDevice.id}
              onAfterSave={() => refresh()}
            />
          ) : (
              <DevicePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
        )}
    </List>
  );

}
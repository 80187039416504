import { ModuleName } from '../../enums/core.enum';
import { AuthRoute } from '../../pages/auth/routes';
import { DashboardAclCode } from '../../pages/dashboard/acl';
import { DashboardRoute } from '../../pages/dashboard/routes';
import { DeviceAclCode } from '../../pages/device/acl';
import { DeviceRoute } from '../../pages/device/routes';
import { SecurityAclCode } from '../../pages/track/acl';
import { SecurityRoute } from '../../pages/track/routes';
import { UserAclCode } from '../../pages/user/acl';
import { UserRoute } from '../../pages/user/routes';
import { VehicleAclCode } from '../../pages/vehicle/acl';
import { VehicleRoute } from '../../pages/vehicle/routes';
import { MenuItem } from './header.interface';

export default [
  {
    title: 'mainMenu.main.dashboard',
    link: DashboardRoute.Overview,
    module: ModuleName.Dashboard,
    icon: 'layout',
    submenu: [
      [
        {
          title: 'mainMenu.dashboard.overview',
          link: DashboardRoute.Overview,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Overview,
        },
        {
          title: 'mainMenu.dashboard.map',
          link: DashboardRoute.Map,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Map,
        },
        {
          title: 'Monitoring',
          link: DashboardRoute.Monitoring,
          module: ModuleName.Dashboard,
          code: DashboardAclCode.Engaging,
        },
      ],
    ],
  },
  {
    title: 'mainMenu.fleet.vehicles',
    link: VehicleRoute.List,
    module: ModuleName.Vehicle,
    submenu: [
      [
        {
          title: 'mainMenu.fleet.vehicles',
          link: VehicleRoute.List,
          module: ModuleName.Vehicle,
          code: [VehicleAclCode.ListAll, VehicleAclCode.ListOwn, VehicleAclCode.Create, VehicleAclCode.Update, VehicleAclCode.Delete],
        },
      ],
    ],
  },
  {
    title: 'mainMenu.security.trackVehicle',
    link: SecurityRoute.TrackVehicle,
    module: ModuleName.Security,
    icon: 'others-43',
    submenu: [
      [
        {
          title: 'mainMenu.security.trackVehicle',
          link: SecurityRoute.TrackVehicle,
          module: ModuleName.Security,
          code: SecurityAclCode.TrackVehicle,
        },
      ],
    ],
  },
  {
    title: 'Device',
    link: DeviceRoute.List,
    module: ModuleName.Device,
    // icon: 'users',
    submenu: [
      [
        {
          title: 'Device',
          link: DeviceRoute.List,
          module: ModuleName.Device,
          code: [DeviceAclCode.ListAllDevice, DeviceAclCode.ListOwnDevice, DeviceAclCode.Create, DeviceAclCode.Update, DeviceAclCode.Delete],
        },
      ],
    ],
  },
  {
    title: 'mainMenu.main.users',
    link: UserRoute.List,
    module: ModuleName.User,
    icon: 'users',
    submenu: [
      [
        {
          title: 'mainMenu.users.users',
          link: UserRoute.List,
          module: ModuleName.User,
          code: [UserAclCode.ListAll, UserAclCode.ListOwn, UserAclCode.Create],
        },
        {
          title: 'mainMenu.users.roles',
          link: UserRoute.ListRole,
          module: ModuleName.User,
          code: [UserAclCode.ListAllRoles, UserAclCode.ListOwnRoles, UserAclCode.CreateRole],
        },
      ],
    ],
  },
  {
    title: 'mainMenu.settings.logout',
    link: AuthRoute.Logout,
    module: ModuleName.User,
    isPublic: true,
    icon: 'log-out',
  },
] as MenuItem[];

import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormUpload, UploadPreviewUrl } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";
import { getUrl } from "../../../../components/form/util";
import { Vehicle } from "../../../../entities/vehicle.entity";

export function VehiclePostRegistration() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  const getProfilePicUrls = (): UploadPreviewUrl[] | undefined => {
    const form: Vehicle = formProps.getValues() as Vehicle;
    if (form.id) {
      return [getProfilePic(form)];
    }
    return undefined;
  }

  const getProfilePic = (vehicle: Vehicle): UploadPreviewUrl => {
    const pic = vehicle.plateFile;
    return {
      thumb: getUrl(`/vehicle/${vehicle.id}/plate/${pic?.id}/admin_single.${pic?.extension}`),
      full: getUrl(`/vehicle/${vehicle.id}/plate/${pic?.id}/admin_large.${pic?.extension}`),
    }
  }
  
  return (
    <FormSection
      title={ t("vehicle.registrationSection.title") }
      subtitle={ t("vehicle.registrationSection.subTitle") }
    >
      <div className="row">
        <FormUpload
          urls={ getProfilePicUrls() }
          label={ t("vehicle.registrationSection.platePictureLabel") }
          description={ t("vehicle.registrationSection.platePictureDescription") }
          name="newPlateFile"
          originalName="plateFile"
          className="col-md-4 col-sm-12"
          validation={{ required: true }}
          tabIndex={4}
          { ...formProps }
        />
        <div className="col-md-4 col-sm-12">
          <FormInput
            label={ t("vehicle.registrationSection.plateNumber") }
            name="plate"
            type="text"
            validation={{ required: true }}
            tabIndex={1}
            autoFocus
            { ...formProps }
          />
          <FormInput
            label={ t("vehicle.registrationSection.registrationExpiry") }
            name="registrationExpiryDate"
            type="date"
            validation={{ required: true }}
            tabIndex={3}
            { ...formProps }
          />
        </div>
        <FormInput
          label={ t("vehicle.registrationSection.vinDigits") }
          name="vin"
          type="number"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={2}
          { ...formProps }
        />
      </div>
    </FormSection>
  );

}

import classNames from 'classnames';
import React from 'react';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import { getErrorMessage } from '../util';


interface Props {
  label: string;
  placeholder?: string | undefined;
  name: string;
  type: 'text' | 'password' | 'email' | 'tel' | 'date' | 'color' | 'number' | 'checkbox'| 'hidden';
  step?: number;
  maxLength?: number;
  validation?: ValidationRules;
  className?: any;
  autoFocus?: boolean;
  value?:any;
  tabIndex?: number;
  onChange?: any;
}
export function FormInput(props: Props & UseFormMethods) {
  const { register, errors, label, placeholder, name, step, type,value ,className,
    onChange, maxLength, autoFocus, tabIndex } = props;
  let { validation } = props;
  validation = validation || {};

  if (type === 'email') {
    validation.pattern = {
      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
      message: getErrorMessage({ type: 'email' }, label),
    };
  }

  return (
    <div className={ classNames('form-group', { 'has-danger': errors && errors[name] }, className) }>
      <label className={ classNames({ required: validation.required }) }>{ label }</label>
      <input
      value={value}
        name={name}
        placeholder={placeholder}
        className="form-control"
        type={type}
        step={step}
        maxLength={maxLength || 1000}
        size={maxLength || 1000}
        autoFocus={autoFocus}
        ref={register ? register(validation) : null}
        autoComplete="turn-off"
        tabIndex={tabIndex}
        onChange={(e) => onChange && onChange(e)}
      />
      {errors && errors[name] && (
      <div className="help-block form-text with-errors form-control-feedback">
        <ul className="list-unstyled"><li>{ getErrorMessage(errors[name], label) }</li></ul>
      </div>
      )}
    </div>
  );
}

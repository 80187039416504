import classNames from 'classnames';
import debounce from 'lodash.debounce';
import React, { useCallback } from 'react';

export enum ListItemStatus {
  Green = 'green',
  Yellow = 'yellow',
  Blue = 'blue',
  Red = 'red',
}

export interface ListItem<Entity> {
  id: string;
  title: string;
  imgSrc?: string;
  status?: ListItemStatus;
  ref: Entity;
}

interface Props<Entity> {
  items?: ListItem<Entity>[];
  activeItem?: ListItem<Entity>;
  showSearch?: boolean;
  showRefresh?: boolean;
  onRefresh?: () => void,
  onSearchChange?: (search: string) => void,
  onClick?: (item: ListItem<Entity>) => void,
  onDoubleClick?: (item: ListItem<Entity>) => void,
}

export function ListItems<Entity>({
  items, activeItem, showSearch, showRefresh, onRefresh, onClick, onSearchChange,
}: Props<Entity>) {

  const search = useCallback(debounce((text: string) => onSearchChange && onSearchChange(text), 600), []);

  return (
    <div className="ae-list-w">
      { (showRefresh || showSearch) && (
        <div className="ael-head ">
          { showSearch && (
            <div className="actions-left">
              <div className="input-search-w">
                <input
                  className="form-control"
                  placeholder="Search..."
                  type="search"
                  onChange={ (e) => search((e.target as HTMLInputElement).value) }
                />
              </div>
            </div>
          )}
          { showRefresh && (
            <div className="actions-right">
              <a href="#refresh" onClick={ onRefresh }><i className="os-icon os-icon-grid-18"></i></a>
            </div>
          )}
        </div>
      )}
      <div className="ae-list">
      {items?.length === 0? 'No Records Found':    
       items?.map(item => (
        <div key={ item.id }
          className={ classNames(
            'ae-item',
            { active: activeItem?.id === item.id },
            { 'with-status': !!item.status },
            item.status ? `status-${item.status}` : null,
          )}
          onClick={ () => { onClick && onClick(item); } }
        >
        { item.imgSrc && (
          <div className="aei-image">
            <div className="user-avatar-w"><img alt={ item.title } src={ item.imgSrc } /></div>
          </div>
        )}
          <div className="aei-content">
            <h6 className="aei-title">{ item.title }</h6>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
}
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ViewContent } from '../../../../components/view/content.component';
import { ViewContentItems } from '../../../../components/view/item.component';
import { Role } from '../../../../entities/role.entity';
import RoleImg from '../../../../img/role.png';
import { capitalize } from '../../../../util/string.util';
import { UserRolePostAssignment } from '../assignment.component';
import { GroupedDisplayAssignment } from './list.component';

interface Props {
    selectedRole: Role;
    selectedDisplayAssignments: GroupedDisplayAssignment[];
}

export function SelectedItemContent({
    selectedRole, selectedDisplayAssignments
}: Props) {

    const {t} = useTranslation('list')

    return (
        <ViewContent
            childClassName="row"
            imgSrc={ RoleImg }
            title={ selectedRole.name }
        >
            <ViewContentItems>
            { selectedDisplayAssignments.length > 0 ? (
                selectedDisplayAssignments.map((display, i) => (
                    <div key={ i } className="element-wrapper mb-4">
                        <h6 className="element-header">{ capitalize(display.module) }</h6>
                        <div className="todo-list d-flex flex-wrap">
                        { display.assignments.map((assignment, j) => (
                            <UserRolePostAssignment
                                key={ j }
                                name={ assignment.id as string }
                                value={ assignment }
                            />
                        ))}
                        </div>
                    </div>
                ))
            ) : (
                <div className="element-wrapper">
                    <div className="element-box">
                        <h2>{t('role.content.heading')}</h2>
                        <p>{ selectedRole.name } {t('role.content.subHeading')}</p>
                    </div>
                </div>
            )}
            </ViewContentItems>
        </ViewContent>
    );
}
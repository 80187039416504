import React from "react";
import { useTranslation } from "react-i18next";
import { DashboardEngagingActivity } from "./activity.component";
import data from './data.json';

export function DashboardMonitoring() {

  const { t } = useTranslation("acl");
  
  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <DashboardEngagingActivity title={t("Vehicles Online")} activities={ data.ongoing } color="secondary" />
          </div>
          {/* <div className="col-sm-12 col-md-3">
            <DashboardEngagingActivity title={t("dashboard.booking.booking-elements.waiting")} activities={ data.waiting } color="primary" />
          </div>
          <div className="col-sm-12 col-md-3">
            <DashboardEngagingActivity title={t("dashboard.booking.booking-elements.way")} activities={ data.enroute } color="success" />
          </div> */}
          <div className="col-sm-12 col-md-3">
            <DashboardEngagingActivity title={t("Devices Offline")} activities={ data.escalated } color="danger" />
          </div>
        </div>
      </div>
    </div>
  );
}
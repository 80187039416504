import React, { useState, useEffect, useCallback } from 'react';
import { ContentWrapper, ViewContent } from '../../../components/view/content.component';
import { ViewContentFiles } from '../../../components/view/files.component';
import { ViewContentItem, ViewContentItems } from '../../../components/view/item.component';
import { IFile } from '../../../entities/file.entity';
import { Vehicle } from '../../../entities/vehicle.entity';
import { getStorageUrl } from '../../../util/file.util';
import { ProfilePicSize } from './list.component'; 
import { Checklist } from './checklist.component';
import { Api, Endpoint } from '../../../services/api.service';
import { IPaginationOptions, IPaginationResponse } from '../../../interfaces/paginate.interface';

interface Props {
  selectedVehicle: Vehicle;
  getProfilePic: (vehicle: Vehicle, size: ProfilePicSize) => string;
  getModel: (vehicle: Vehicle) => string;
}

export function SelectedItemContent({
  selectedVehicle,
  getProfilePic,
  getModel,
}: Props) {
  const [vehicle, setVehicle] = useState<Vehicle>();  

  // const ref = useCallback(() => {
  //   const refresh = async () => {
  //     const { items } = await Api.get<IPaginationResponse<Inspection>, IPaginationOptions>(Endpoint.INSPECTION_LIST, { page: 0, limit: 20, });
  //     if (items) {
  //       const data = items.filter(items => (items.type === InspectionType.Vehicle))
  //       setInspection(data);
  //       setInspectionAssignment([]);

  //       data.forEach((VehicleInspectionData) => {
  //         let finalStatus: InspectionAssignmentStatus = InspectionAssignmentStatus.Pending;
  //         setInspectionAssignment((items) => {
  //           selectedVehicle?.inspection?.forEach((InspectionData) => {
  //             if (InspectionData.id === VehicleInspectionData.id) {
  //               finalStatus = InspectionAssignmentStatus.Done;
  //             }
  //           })
  //           return ([...items, {
  //             id: selectedVehicle?.id,
  //             inspection: VehicleInspectionData,
  //             entity: 'Vehicle',
  //             status: finalStatus,
  //           }])
  //         })
  //       })
  //     }

  //     console.log('Inspection Assignment :', inspectionAssignment);
  //   };
  //   refresh();
  // }, [selectedVehicle, inspectionAssignment])

  const init = useCallback(async () => {
    setVehicle(selectedVehicle);
    const vehicle = await Api.get<Vehicle, { id: string }>(Endpoint.VEHICLE, { id: selectedVehicle.id as string });
    setVehicle(vehicle);
  }, [selectedVehicle]);

  useEffect(() => {
    init();
    // ref();
  }, [selectedVehicle, init])

  const stats = [
    {
      title: "Rides",
      value: "0",
      percentage: "0",
      trend: "neutral",
    },
    {
      title: "Earnings",
      value: "$0",
      percentage: "0",
      trend: "neutral",
    },
    {
      title: "Earnings per Ride",
      value: "$0",
      percentage: "0",
      trend: "neutral",
    },
    {
      title: "Shift Time",
      value: "0 Hrs",
      percentage: "0",
      trend: "neutral",
    },
  ];

  const {
    plate,
    vin,
    registrationExpiryDate,
    numLuggage,
    numPassengers,
    plateFile,
    profileFiles,
  } = selectedVehicle;



  const getFileSrc = (file: IFile): { thumb: string, full: string } => {
    return {
      thumb: `${getStorageUrl()}/vehicle/${vehicle?.id}/${file.type}/${file.id}/admin_single.${file.extension}`,
      full: `${getStorageUrl()}/vehicle/${vehicle?.id}/${file.type}/${file.id}/admin_large.${file.extension}`,
    }
  }

  // const newVal = async (i: InspectionAssignment) => {
  //   if (i?.status === InspectionAssignmentStatus.Done) {
  //     if (i.inspection)
  //       selectedVehicle?.inspection.push(i.inspection)
  //     await Api.patch(Endpoint.VEHICLE, { id: selectedVehicle.id, inspection: selectedVehicle.inspection });
  //   }
  //   else if (i.status === InspectionAssignmentStatus.Pending) {
  //     selectedVehicle.inspection = selectedVehicle.inspection.filter(item => item.id !== i.inspection?.id)
  //     await Api.patch(Endpoint.VEHICLE, { id: selectedVehicle.id, inspection: selectedVehicle.inspection });
  //   }
  //   ref();
  // };

  return (
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={plate}
        imgSrc={getProfilePic(selectedVehicle, ProfilePicSize.Single)}
      >
        <ViewContentItems>
          <ViewContentItem title="VIN">{vin}</ViewContentItem>

          <ViewContentItem title="Registration Expiry">
            {registrationExpiryDate}
          </ViewContentItem>

          <ViewContentItem title="Model">
            {getModel(selectedVehicle)}
          </ViewContentItem>

          <ViewContentItem title="Passengers">{numPassengers}</ViewContentItem>

          <ViewContentItem title="Luggage">{numLuggage}</ViewContentItem>
        </ViewContentItems>

        <ViewContentFiles
          groups={[
            {
              title: "Pictures",
              files: profileFiles?.map(getFileSrc),
            },
            { title: "Plate", files: plateFile ? [getFileSrc(plateFile)] : [] },
          ]}
        />

        <div className="row">
          {stats.map((stat: any, i: number) => (
            <div key={i} className="col-sm-12 col-md-6">
              <a className="element-box el-tablo" href="#stat">
                <div className="label">{stat.title}</div>
                <div className="value">{stat.value}</div>
                <div className={`trending trending-${stat.trend}-basic`}>
                  <span>${stat.percentage}%</span>
                  <i
                    className={`os-icon os-icon-arrow-${stat.trend === "positive" ? "up2" : "down"
                      }`}
                  ></i>
                </div>
              </a>
            </div>
          ))}
        </div>
      </ViewContent>
    </ContentWrapper>
  );
}

import { Acl } from "../../interfaces/core.interface";

export enum DashboardAclCode {
  Overview = 'overview',
  Map = 'map',
  Revenue = 'revenue',
  Engaging = 'engaging',
  Trip = 'trip',
}

export const DashboardAcl = [
  {
    code: DashboardAclCode.Overview,
    title: 'dashboard.overview.title',
    description: 'dashboard.overview.subTitle',
  },
  {
    code: DashboardAclCode.Map,
    title: 'dashboard.map.title',
    description: 'dashboard.map.subTitle',
  },
  {
    code: DashboardAclCode.Revenue,
    title: 'dashboard.revenue.title',
    description: 'dashboard.revenue.subTitle',
  },
  {
    code: DashboardAclCode.Engaging,
    title: 'Live Engaging',
    description: 'Live Engaging between student and mentor',
  },
  {
    code: DashboardAclCode.Trip,
    title: 'dashboard.trip.title',
    description: 'dashboard.trip.subTitle',
  },
] as Acl<DashboardAclCode>;

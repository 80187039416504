import { Role } from "../entities/role.entity";  
import { DashboardRoute } from "../pages/dashboard/routes"; 
import { UserRoute } from "../pages/user/routes";     
import { User } from "../entities/user.entity"; 
import { Vehicle } from "../entities/vehicle.entity";
import { Device } from "../entities/device.entity";
import { VehicleRoute } from "../pages/vehicle/routes";
import { DeviceRoute } from "../pages/device/routes";


export enum FormHeaderType {
  Title = "title",
  Controls = "controls",
}

export type FormCancelLink = 
    UserRoute |   
    DashboardRoute |
    VehicleRoute |
    DeviceRoute 

export type Entities = 
    Vehicle |
    User | 
    Role |  
    Device ;

export enum FormStyle {
  Containerized = "Containerized",
}

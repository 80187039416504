import React from "react";
import { DashboardOverviewStats } from "./stats.component";
import { DashboardOverviewTodo } from "./todo.component";

export function DashboardOverview() {
  return (
    <div className="row">
      <DashboardOverviewStats />
      <DashboardOverviewTodo />
    </div>
  )
}

import React, { useState, useEffect, useCallback } from 'react';
import { ContentWrapper, ViewContent } from '../../components/view/content.component'; 
import { ViewContentItem, ViewContentItems } from '../../components/view/item.component';   
import { Device } from '../../entities/device.entity';
import { Api, Endpoint } from '../../services/api.service';

interface Props {
  selectedDevice: Device; 
}

export function SelectedItemContent({
  selectedDevice, 
}: Props) {
  const [device, setDevice] = useState<Device>(); 

  const init = useCallback(async () => {
    setDevice(selectedDevice);
    const device = await Api.get<Device, { id: string }>(Endpoint.DEVICE, { id: selectedDevice.id as string });
    setDevice(device);
  }, [selectedDevice]);

  useEffect(() => {
    init(); 
  }, [selectedDevice, init])

  const {
    name,
    IMEI,
    serialNumber, 
  } = selectedDevice;
 

  return (
    <ContentWrapper className="row">
      <ViewContent
        className="col-md-6"
        title={name} 
      >
        <ViewContentItems>
          <ViewContentItem title="IMEI">{IMEI}</ViewContentItem>

          <ViewContentItem title="Serial Number">
            {serialNumber}
          </ViewContentItem> 
        </ViewContentItems>
      </ViewContent>
    </ContentWrapper>
  );
}

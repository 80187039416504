import { DirectionsRenderer, GoogleMap, Marker, TrafficLayer } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import car from '../../../img/car.svg'
import { FormInput } from "../../components/form/input/input.component";
import { ContentWrapper, ViewContent } from "../../components/view/content.component";
import { ViewContentItem, ViewContentItems } from "../../components/view/item.component";
import { add } from "date-fns";
import { Api, ApiType, Endpoint } from "../../services/api.service";  
import { Vehicle } from "../../entities/vehicle.entity";    
import { IFile } from "../../entities/file.entity";
import { getStorageUrl } from "../../util/file.util"; 
import FemaleImg from "../../../img/female.png"
import MaleImg from '../../../img/male.png';
import { useParams } from "react-router"; 

export function TrackVehicle(props: any) {
  const formProps = useFormContext();

  const [center, setCenter] = useState<google.maps.LatLng>();
  const [zoom, setZoom] = useState(10); 
  const [map, setMap] = useState<google.maps.Map>() 
  const [point, setPoint] = useState<google.maps.Point>();
  const [size, setSize] = useState<google.maps.Size>();
  const [code, setCode] = useState<string>('');  
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [sendData, setSendData] = useState<boolean>(true); 

  let params: any = useParams();


  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map) 
    setTimeout(() => {
      // Sydney
      const DEFAULT_CENTER = new google.maps.LatLng(32.64178306356345, 74.02090887876405);
      const DEFAULT_POINT = new google.maps.Point(32, 32);
      const DEFAULT_SIZE = new google.maps.Size(64, 64);
      setCenter(DEFAULT_CENTER);
      setPoint(DEFAULT_POINT)
      setSize(DEFAULT_SIZE)
      setZoom(20); 
    }, 100); 

  }, [])
   

  return (
    <ContentWrapper className="container-fluid">
      <div className="row">
        <FormInput
          label={""}
          name="Track"
          type="text"
          className="col-sm-6 mb-0 ml-3"
          tabIndex={10}
          placeholder="Enter Vehicle Plate no" 
          {...formProps}
        />
        <button
          style={{ height: "100%", marginTop: "23px", padding: "8px" }}
          className="btn btn-primary text-bold col-sm-1" 
        >
          Track
        </button>
        <FormInput
          label={""}
          name="code"
          type="text"
          className="col-sm-6 ml-3"
          tabIndex={12}
          value={code}
          placeholder="Enter Device IMEI" 
          {...formProps}
        />
        <button
          style={{ height: "100%", marginTop: "23px", padding: "8px" }}
          className="btn btn-primary text-bold col-sm-1" 
        >
          Track
        </button>
      </div>
      <div className="row">
        <div className='col-md-7'>
          <GoogleMap
            mapContainerStyle={{
              marginLeft: 20,
              height: 475,
              width: "98%",
              padding: 8,
              marginBottom: 10,
            }}
            onLoad={onLoad} 
            center={center}
            zoom={zoom}
          > 
          </GoogleMap>
        </div>
        <div className='col-md-5'>
          <div className='row mb-2'>
            <div className='col-md-12'>
            </div>
          </div>
          <div className='row'>
            <ViewContent className="col-md-6" title="Vehicle Details">
              <ViewContentItems>
                <ViewContentItem title="VIN">{vehicle?.vin}</ViewContentItem>
                <ViewContentItem title="Registration Expiry">{vehicle?.registrationExpiryDate}</ViewContentItem>
                <ViewContentItem title="Model">{vehicle?.model}</ViewContentItem>
                <ViewContentItem title="Passengers">{vehicle?.numPassengers}</ViewContentItem>
                <ViewContentItem title="Luggage">{vehicle?.numLuggage}</ViewContentItem>
              </ViewContentItems>
            </ViewContent>
            {/* {driver ?
              <ViewContent className="col-md-6" title="Driver Details"
                subtitle={driver.code}
                imgSrc={getProfilePic(driver, ProfilePicSize.Single)}
              >
                <ViewContentItems>
                  <ViewContentItem title="Name">{driver.firstName}</ViewContentItem>
                  <ViewContentItem title="Email">{driver.email}</ViewContentItem>
                  <ViewContentItem title="Phone">{driver.phone}</ViewContentItem>
                  <ViewContentItem title="Gender">{driver.gender}</ViewContentItem>
                  <ViewContentItem title="Date of Birth">{driver.dateOfBirth}</ViewContentItem>
                </ViewContentItems>
              </ViewContent> : */}
              <ViewContent className="col-md-6" title="Device Details"
              >
                <ViewContentItems>
                  <ViewContentItem title="Name"></ViewContentItem>
                  <ViewContentItem title="IMEI"></ViewContentItem>
                  <ViewContentItem title="Serial Number"></ViewContentItem> 
                </ViewContentItems>
              </ViewContent>
            {/* } */}
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
}
import { EventEmitter } from "events";
import { AuthService } from "./auth.service";

interface InitProps {
  id: string;
}

class ChatServiceClass extends EventEmitter {
  private client = (window as any).converse;
  private static instance: ChatServiceClass;
  private readonly tokenKey = 'chatToken';
  private readonly userKey = 'chatUser';
  private chatToken: any = null;
  private storage: Storage | null = null;

  public static getInstance(): ChatServiceClass {
    if (!ChatServiceClass.instance) {
      ChatServiceClass.instance = new ChatServiceClass();
    }

    return ChatServiceClass.instance;
  }

  setChatToken(token: string, rememberMe: boolean) {
    this.chatToken = token;
    this.storage = rememberMe ? localStorage : sessionStorage;
    this.storage.setItem(this.tokenKey, token);

    this.init();
  }

  getChatToken(): string | null | undefined {
    const token = AuthService.getStorage()?.getItem(this.tokenKey);
    this.chatToken = token;
    return token;
  }

  init() {
    try {
      const user = AuthService.getUser();
      const jid: string = `${user?.id}@av.taxis.plus`;
      const token = this.chatToken ? this.chatToken : this.getChatToken();
      console.log('Initialized chat service');
      if (user?.id && token) {
        // Registering the logout plugin
        this.client.plugins.add('logoutPlugin', {
          initialize: (props: any) => {
            console.log('Logout plugin initialized');
            // Setting the event listener to listen to logout evetns
            const _converse = props.properties._converse;
            this.once('chat_logout', () => {
              this.chatToken = null;
              _converse.api.user.logout();
            });
          },
        });

        // Registering the disconnected plugin
        this.client.plugins.add('disconnectedPlugin', {
          initialize: (props: any) => {
            console.log('Disconnection plugin initialized');

            const _converse = props.properties._converse;
            _converse.api.listen.on('disconnected', () => {
              document.getElementById('conversejs')?.remove();
            });
          },
        });

        // Initializing the chat service
        this.client.initialize({
          websocket_url: 'ws://av.taxis.plus:5443/ws',
          jid: jid,
          keepalive: false,
          discover_connection_methods: false,
          password: token,
          auto_login: true,
          allow_logout: false,
          whitelisted_plugins: ['logoutPlugin', 'location', 'disconnectedPlugin'],
        });
      }
    } catch (e) {
      console.log('Error occured while starting the chat service');
      console.log(e);
    }
  }

  disconnect() {
    /**
     * Emitting a custom event so that the converse's
     * custom plugin knows that we want it to disconnect
     * from the xmpp server and removing the conversejs 
     * interface manually since no api is available to do that
     */
    this.emit('chat_logout');
    document.getElementById('conversejs')?.remove();
    AuthService.getStorage()?.removeItem(this.tokenKey);
  }

  getClient() {
    return this.client;
  }
}

const ChatService: ChatServiceClass = ChatServiceClass.getInstance();
const ChatClient = ChatService.getClient();

export { ChatService, ChatClient };

/**
 * Functions to handle different stuff required above
 */
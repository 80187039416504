import classNames from 'classnames';
import React from 'react';
import { UseFormMethods, ValidationRules } from 'react-hook-form';
import { getErrorMessage } from '../util';

interface Props {
  label: string;
  name: string;
  className?: string; 
  options: RadioOption[];
  validation: ValidationRules;
  tabIndex?: number;
}

export interface RadioOption {
  label: string;
  value: string;
  selected?: boolean;
}

export function FormRadio(props: Props & UseFormMethods) {
  const { register, validation, errors, label, name, className, options, tabIndex } = props;

  return (
    <div className={classNames('form-group', 'vertical', className, { 'has-danger': !!errors[name] })}>
      <label className={validation.required ? 'required' : ''}>{ label }</label>
      {options.map(option => (
      <label key={option.value} className="form-check-label">
        <input
          ref={register(validation)} 
          className="form-check-input" 
          name={name}
          type="radio" 
          value={option.value} 
          checked={option.selected}
          tabIndex={tabIndex}
      /> {option.label}
      </label>
      ))}
      {errors[name] && (
      <div className="help-block form-text with-errors form-control-feedback">
        <ul className="list-unstyled"><li>{ getErrorMessage(errors[name], label) }</li></ul>
      </div>
      )}
    </div>
  );
}

import Axios, { AxiosError, AxiosResponse } from "axios";
import { EventEmitter } from "events";
import WebSocket from "reconnecting-websocket";
import { v4 as uuid } from "uuid";
import { User } from "../entities/user.entity"; 
import { AuthService } from "./auth.service"; 

// Endpoints
export enum Endpoint {
  LOGIN = "user/login",
  Forgot_Password = "user/forgotPassword",
  Password_Recovery = "user/passwordRecovery",
  REGISTER = "user/register",

  USER = "user",
  USER_LIST = "user/list",
  USER_ROLE = "user/role",
  USER_ROLE_LIST = "user/role/list",

  VEHICLE = "vehicle",
  VEHICLE_LIST = "vehicle/list",
  VEHICLE_FILES = "vehicle/files",

  DASHBOARD_COMPLETION = "dashboard/completion",
  DASHBOARD_BOOKING = "dashboard/booking",
  DASHBOARD_STATISTICS = "dashboard",
  DASHBOARD_TRIP = "dashboard/trip", 

  INTEREST = "interest",
  INTEREST_LIST = "interest/list", 

  REGION = "region",
  REGION_LIST = "region/list",

  GROUP = "group",
  GROUP_LIST = "group/list",
  
  ANNOUNCEMENT = "announcement",
  ANNOUNCEMENT_LIST = "announcement/list",

  DEVICE = "device",
  DEVICE_LIST = "device/list", 

  STUDENT = "student",
  STUDENT_LIST = "student/list",
  STUDENT_FILES = "student/files", 

  MENTOR = "mentor",
  MENTOR_LIST = "mentor/list",
  MENTOR_FILES = "mentor/files", 

  SPEAKER = "speaker",
  SPEAKER_LIST = "speaker/list",
  SPEAKER_FILES = "speaker/files", 
}

export enum ApiType {
  Rest,
  Socket,
}

// const process = {
//   env: {
//     REACT_APP_API_URL: "http://192.168.0.108:3000/v1/",
//     REACT_APP_WS_URL: "ws://192.168.0.108:3000/"
//   }
// }
let check = false;
class ApiClass extends EventEmitter {
  private readonly axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
  });

  private socket: WebSocket | undefined;

  constructor() {
    super();

    Axios.interceptors.response.use(
      (response: AxiosResponse) => {
        console.log("INTERCEPTED");
        return response.data;
      },
      (error: AxiosError) => {
        if (
          error.response?.status === undefined ||
          error.response?.status >= 500
        ) {
          console.error("API Server Error", error);
          // Show dialog
          return;
        }
      }
    );

    // if (AuthService.isLoggedIn()) {
    this.connect();
    // }
  }

  connect() {
    this.socket = new WebSocket(
      process.env.REACT_APP_WS_URL as string,
      undefined,
      { debug: true }
    );
    this.socket.addEventListener("open", () => {
      this.init();
    });
  }

  async init() {
    this._listen();
    const response: {
      user: User;
      // settings: Settings;
    } = await this._emit("init", {
      accessToken: AuthService.getAccessToken(),
      lang: "en",
    });
    AuthService.setUser(response.user);
    // SettingsService.setSetting(response.settings);
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = undefined;
    }
  }

  async get<Response, Params>(
    endpoint: Endpoint,
    params?: Params,
    type = ApiType.Socket
  ): Promise<Response> {
    if (type === ApiType.Socket) {
      return this._emit(`get:${endpoint}`, params);
    }
    const response = await this.axios.get(endpoint, { params });
    return response.data;
  }

  async post<Response, Params>(
    endpoint: Endpoint,
    data?: Params,
    type = ApiType.Socket
  ): Promise<Response> {
    if (type === ApiType.Socket) {
      return this._emit(`post:${endpoint}`, data);
    }
    const response = await this.axios.post(endpoint, data);
    return response.data;
  }

  async patch<Response, Params>(
    endpoint: Endpoint,
    data?: Params,
    type = ApiType.Socket
  ): Promise<Response> {
    if (type === ApiType.Socket) {
      return this._emit(`patch:${endpoint}`, data);
    }
    const response = await this.axios.patch(endpoint, data);
    return response.data;
  }

  async delete<Response, Params>(
    endpoint: Endpoint,
    data?: Params,
    type = ApiType.Socket
  ): Promise<Response> {
    if (type === ApiType.Socket) {
      return this._emit(`delete:${endpoint}`, data);
    }
    const response = await this.axios.delete(endpoint);
    return response.data;
  }

  async upload<Response, Params>(
    endpoint: Endpoint,
    data: Params,
    progressHandler?: (event: ProgressEvent) => void,
    type = ApiType.Rest
  ): Promise<Response> {
    if (type === ApiType.Socket) {
      throw new Error("Websocket uploads not supported");
    }
    const headers = { authorization: `Bearer ${AuthService.getAccessToken()}` };
    const response = await this.axios.post(endpoint, data, {
      headers,
      onUploadProgress: progressHandler,
      timeout: 0,
    });
    return response.data;
  }

  private _emit<Response, Params>(
    event: string,
    data?: Params
  ): Promise<Response> {
    let handled = false;
    const requestId = uuid();

    if (data) {
      (data as any).requestId = requestId;
    } else {
      data = { requestId } as any;
    }
    return new Promise((resolve, reject) => {
      this.once(`response:${requestId}`, (_, data) => {
        handled = true;
        resolve(data);
      });
      this.once(`error:${requestId}`, (_, error, field) => {
        handled = true;
        reject({ message: error, field });
      });
      this.socket?.send(JSON.stringify({ event, data }));
      setTimeout(() => {
        if (handled) {
          return;
        }
        console.warn("Server failed to respond in a timely manner");
        reject("Server failed to respond in a timely manner");
      }, 60000);
    });
  }

  private _listen() {
    this.socket?.addEventListener("message", (e: MessageEvent) => {
      console.log('Methods:', e);
      if (e.data) {
        if (!check) {
          this.emit('initializing');
          check = true
        }
        const { requestId, event, data, error, field } = JSON.parse(e.data);
        if (requestId) {
          if (error) {
            this.emit(`error:${requestId}`, event, error, field);
          } else {
            this.emit(`response:${requestId}`, event, data);
            if (event == 'response:init') {
              this.emit('initialized');
            }
          }
        } else {
          const { event, data } = JSON.parse(e.data);
          switch (event) {
            case "emergency_alert":
              this.emit("emergency_alert", data);
              break;
            case "booking_alert":
              this.emit("booking_alert", data);
              break;
            case "gps_latest_location":
              this.emit("gps_latest_location", data);
            case "modify_alert":
              this.emit("modify_alert", data)
              break;
          }
          // else if(event == "session_alert"){
          //   this.emit("session_alert", data)
          // }
          // else if(event == "session_update"){
          //   this.emit("session_update", data)
          // }
          // else if(event == "trip_alert"){
          //   this.emit("trip_alert", data)
          // }
          // else if(event == "trip_update"){
          //   this.emit("trip_update", data)
          // }
        }
      }
    });
  }
}

export const Api = new ApiClass();

import { Acl } from "../../interfaces/core.interface";

export enum UserAclCode {
  ListAll = 'list-all',
  ListOwn = 'list-own',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Approve = 'approve',
  Block = 'block',
  ListAllRoles = 'list-all-roles',
  ListOwnRoles = 'list-own-roles',
  CreateRole = 'create-role',
  UpdateRole = 'update-role',
  DeleteRole = 'delete-role',
}

export const UserAcl = [
  {
    code: UserAclCode.ListAll,
    title: 'user.listAll.title',
    description: 'user.listAll.subTitle',
  },
  {
    code: UserAclCode.ListOwn,
    title: 'user.listOwn.title',
    description: 'user.listOwn.subTitle',
  },
  {
    code:  UserAclCode.Create,
    title: 'user.create.title',
    description: 'user.create.subTitle',
  },
  {
    code: UserAclCode.Update,
    title: 'user.update.title',
    description: 'user.update.subTitle',
  },
  {
    code: UserAclCode.Delete,
    title: 'user.delete.title',
    description: 'user.delete.subTitle',
  },
  {
    code: UserAclCode.Approve,
    title: 'user.approve.title',
    description: 'user.approve.subTitle',
  },
  {
    code: UserAclCode.Block,
    title: 'user.block.title',
    description: 'user.block.subTitle',
  },
  {
    code: UserAclCode.ListAllRoles,
    title: 'user.listAllRoles.title',
    description: 'user.listAllRoles.subTitle',
  },
  {
    code: UserAclCode.ListOwnRoles,
    title: 'user.listOwnRoles.title',
    description: 'user.listOwnRoles.subTitle',
  },
  {
    code: UserAclCode.CreateRole,
    title: 'user.createRole.title',
    description: 'user.createRole.subTitle',
  },
  {
    code: UserAclCode.UpdateRole,
    title: 'user.updateRole.title',
    description: 'user.updateRole.subTitle',
  },
  {
    code: UserAclCode.DeleteRole,
    title: 'user.deleteRole.title',
    description: 'user.deleteRole.subTitle',
  },
] as Acl<UserAclCode>;

import { User } from '../entities/user.entity';
import { Api } from './api.service';

class AuthServiceClass {
  private readonly tokenKey = 'accessToken';
  private readonly userKey = 'user';
  private accessToken: any = null;
  private storage: Storage | null = null;

  setAccessToken(token: string, rememberMe:boolean) {
    this.accessToken = token;
    this.storage = rememberMe ? localStorage : sessionStorage;
    this.storage.setItem(this.tokenKey, token);
  
    Api.connect();
  }

  getStorage() {
    if (this.storage) {
      return this.storage;
    }
    let token = sessionStorage.getItem(this.tokenKey);
    if (token) {
      this.storage = sessionStorage;
      return sessionStorage;
    }
    token = localStorage.getItem(this.tokenKey);
    if (token) {
      this.storage = localStorage;
      return localStorage
    }
    return null;
  }

  getAccessToken(): string | null | undefined {
      const token = this.getStorage()?.getItem(this.tokenKey);
      this.accessToken = token;
      return token;
  }

  setUser(user: User) {
    if(this.storage !== null) this.storage.setItem(this.userKey, JSON.stringify(user)); 
  }

  getUser(): User | undefined {
    if(this.storage != null){
      const userString = this.storage.getItem(this.userKey);
      if (userString) {
        return JSON.parse(userString);
      }
      return;
    }
  }

  logout() {
      this.accessToken = null;
      this.storage?.clear();
      Api.disconnect();
   }

  isLoggedIn(): boolean {
    return !!this.getAccessToken();
    // return true;
  }
}

export const AuthService = new AuthServiceClass();
import { LoadScript } from "@react-google-maps/api";
import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { Helmet } from "react-helmet"; 
import { Header } from "./components/header/header.component";
import { PrivateRoute } from "./pages/auth/private-route.component";
import { AuthRoute, AuthRouteMap } from "./pages/auth/routes";
import { DashboardAclCode } from "./pages/dashboard/acl";
import { DashboardModule } from "./pages/dashboard/module";
import { DashboardRoute } from "./pages/dashboard/routes";  
import { UserAclCode } from "./pages/user/acl";
import { UserModule } from "./pages/user/module"; 
import "./scss/main.scss";
import { AclService } from "./services/acl.service";
import { AuthService } from "./services/auth.service";
import "./services/i18n";
import * as serviceWorker from "./serviceWorker";   
import { Api } from "./services/api.service";  
import { VehicleAclCode } from "./pages/vehicle/acl";
import { VehicleModule } from "./pages/vehicle/module";
import { DeviceAclCode } from "./pages/device/acl";
import { DeviceModule } from "./pages/device/module";
import { SecurityModule } from "./pages/track/module";
import { SecurityAclCode } from "./pages/track/acl";

const favicon = require(`./img/${process.env.REACT_APP_FAVICON}`);

AclService.setModuleActions<DashboardAclCode>(
  DashboardModule.name,
  DashboardModule.acl
);  
AclService.setModuleActions<UserAclCode>(UserModule.name, UserModule.acl); 
AclService.setModuleActions<VehicleAclCode>(VehicleModule.name, VehicleModule.acl);  
AclService.setModuleActions<DeviceAclCode>(DeviceModule.name, DeviceModule.acl);
AclService.setModuleActions<SecurityAclCode>(SecurityModule.name, SecurityModule.acl); 




const routeMap: RouteProps[] = [
  DashboardModule.routes,  
  UserModule.routes,
  VehicleModule.routes,  
  DeviceModule.routes, 
  SecurityModule.routes,
].reduce((a, v) => a.concat(v), []);

export default function App() {
  // const [requests, setRequests] = useState<Emergency[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Api.on('initializing', () => setLoading(true));
    Api.on('initialized', () => setLoading(false));
  }, []) 

//   useEffect(() => {
//     // if(AuthService.isLoggedIn()) {
//     //   ChatService.init();
//     // }
//     // Api.on('emergency_alert', async (payload: any) => {
//     //   console.log("RECEIVED EMERGENCY EVENT!");
//     //   await EmergencyFunctions.handleRequest(payload).then(async () => {
//     //     console.log("Pending Requests: " + EmergencyFunctions.CountPendingRequests());
//     //   await EmergencyFunctions.handleRequest(payload).then(()=>{
//     //     setRequests([...EmergencyFunctions.GetAllRequests()]);
//     //   });
//     // });
//     // Api.on('modify_alert', async(payload: any)=>{
//     //   EmergencyFunctions.updatePendings(payload);
//     //   setRequests([...EmergencyFunctions.GetAllRequests()]);
//     // });
//     // ChatService.init();
//   // });
// }, []);

  return (
    <div className="auth-container all-wrapper with-side-panel solid-bg-all">
     <Helmet>
        <title>{`${process.env.REACT_APP_NAME} Admin`}</title>
        <link rel='icon' href={favicon} />
      </Helmet>
      <div className="layout-w">
        {/* Only show header when authenticated */}
        {loading && <div>Loading...</div>}
        {AuthService.isLoggedIn() && !loading && <Header />}
        <div className="content-w">
          <Switch>
            {/* Private routes require authentication */}
            <Route exact path="/">
              <Redirect
                to={
                  (!loading && AuthService.isLoggedIn())
                    ? DashboardRoute.Overview
                    : AuthRoute.Login
                }
              />
            </Route>
            {routeMap.map((routeProps) => (
              <PrivateRoute key={routeProps.path as string} {...routeProps} loading={loading} />
            ))}
            {/* Authentication routes - public */}
            {AuthRouteMap.map((routeProps) => (
              <Route key={routeProps.path as string} {...routeProps} />
            ))}
            <Route path="*">
              <Redirect
                to={
                  (!loading && AuthService.isLoggedIn())
                    ? DashboardRoute.Overview
                    : AuthRoute.Login
                }
              />
            </Route>
          </Switch>
          {/* <RequestStacker Data={requests} MaxRequests={3}/> */}
        </div>
      </div>
    </div>
  );
}

const AppRouter = withRouter(App);

ReactDOM.render(
  <Suspense fallback="loading">
    <Router>
      <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/react-draft-wysiwyg@1.12.3/dist/react-draft-wysiwyg.css' />
      <LoadScript
        googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
        libraries={["places", "drawing"]}
      >
        <AppRouter />
      </LoadScript>
    </Router>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

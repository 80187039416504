import React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthService } from '../../services/auth.service';
import { ChatService } from '../../services/chat.service';
import { AuthRoute } from './routes';

export function AuthLogout() {

  ChatService.disconnect();
  AuthService.logout();

  return (
    <Redirect to={ AuthRoute.Login } />
  );

}

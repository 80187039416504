import { Acl } from "../../interfaces/core.interface";

export enum DeviceAclCode { 
  ListAllDevice = 'list-all',
  ListOwnDevice = 'list-own', 
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export const DeviceAcl = [  
  {
    code: DeviceAclCode.ListAllDevice,
    title: 'majors.listAll.title',
    description: 'majors.listAll.subTitle',
  },
  {
    code: DeviceAclCode.ListOwnDevice,
    title: 'majors.listOwn.title',
    description: 'majors.listOwn.subTitle',
  },
  {
    code: DeviceAclCode.Create,
    title: 'majors.create.title',
    description: 'majors.create.subTitle',
  },
  {
    code: DeviceAclCode.Update,
    title: 'majors.update.title',
    description: 'majors.update.subTitle',
  },
  {
    code: DeviceAclCode.Delete,
    title: 'majors.delete.title',
    description: 'majors.delete.subTitle',
  }, 
] as Acl<DeviceAclCode>;

import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../components/form/form.component";
import { FormHeader } from "../../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../../components/form/sections.component";
import { User } from "../../../../entities/user.entity";
import { FormMode } from "../../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../../enums/form.enum";
import { Endpoint } from '../../../../services/api.service';
import { UserRoute } from "../../routes";
import { UserPostPersonal } from "./section/personal.component";
import { UserPostRoleSelect } from "./section/role.component";

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
}

/**
 * Add or update a user
 */
export function UserPost({
  style, cancel, formMode, entityId
}: Props) {

  const { t } = useTranslation('main');

  const onBeforeSave = async (mode: FormMode, form: User) => {
    if (!form.role) {
      throw new Error('Role needs to be specified')
    }
  }


  /**
   * Custom Header depending upon the type of form style
   * In case of containerized style, we're seperating headers in the seperate SectionsWrapper tags,
   * Otherwise if the form is supposed to be shown on full screen then don't use custom header, but rather
   * Use it seperately for each FormSections tag as shown below
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Title} title={t("entities.user")} />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={FormHeaderType.Controls} />
        </SectionsWrapper>
      </>
    ) : (
        <FormHeader type={FormHeaderType.Title} title={t("entities.role")} />
      )
  );

  return (
    <Form
      endpoint={Endpoint.USER}
      onBeforeSave={onBeforeSave}
      returnLink={UserRoute.List}
      cancel={cancel}
      formMode={formMode}
      entityId={entityId}
      className={style === FormStyle.Containerized && "ae-content-w"}
    >
      {/* Using the custom header in case of using containerized form */}
      { style === FormStyle.Containerized && header()}

      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {/* If not containerized, then use it normally */}
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Title} title={t("entities.user")} />}

        <FormSections>
          <UserPostPersonal />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={style === FormStyle.Containerized ? "col-lg-12" : ""}>
        {/* If not containerized, then use it normally */}
        {style !== FormStyle.Containerized && <FormHeader type={FormHeaderType.Controls} />}

        <FormSections>
          <UserPostRoleSelect />
        </FormSections>
      </SectionsWrapper>
    </Form>
  );
}

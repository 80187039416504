import { Acl } from "../../interfaces/core.interface";

export enum SecurityAclCode { 
  TrackVehicle = 'track', 
}

export const SecurityAcl = [
  {
    code: SecurityAclCode.TrackVehicle,
    title: 'security.track.title',
    description: 'security.track.subTitle',
  },
] as Acl<SecurityAclCode>;
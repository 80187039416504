export enum ModuleName {
  Dashboard = 'dashboard',
  User = 'user',
  Vehicle = 'vehicle',
  Device = 'device', 
  Security = 'security', 
}

export enum FormMode {
  Adding,
  Editing,
}

import React from "react";
import { Bar, Doughnut } from "react-chartjs-2";

import { useTranslation } from "react-i18next";

const mainLabels = ['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', 
'12pm', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm'];

const mainData = generateData(16, 20);

const mainChartData = {
  labels: mainLabels,
  datasets: [
    {
      label: 'Revenue',
      fill: true,
      backgroundColor: mainData.map(v => (v < 30 ? '#ff0000' : (v >= 30 ? '#00ff00' : '#1b55e2'))),
      borderColor: '#9152ad55',
      data: mainData,
    },
  ]
}

const zoneChartData = {
  labels: ['Sydney', 'Melbourne'],
  datasets: [
    {
      label: 'Revenue',
      fill: true,
      backgroundColor: ["#4ecc48", "#ffcc29"],
      data: [498, 0]
    },
  ]
}

const serviceChartData = {
  labels: ['Economy', 'Economy Plus', 'Luxury'],
  datasets: [
    {
      label: 'Revenue',
      fill: true,
      backgroundColor: ["#4ecc48", "#ffcc29", "#fff012"],
      data: [329, 259, 0]
    },
  ]
}

const channelChartData = {
  labels: ['App', 'Dispatch', 'Hail'],
  datasets: [
    {
      label: 'Revenue',
      fill: true,
      backgroundColor: ["#4ecc48", "#ffcc29", "#fff012"],
      data: [20, 56, 422]
    },
  ]
}

const typeChartData = {
  labels: ['Regular', 'Carpool'],
  datasets: [
    {
      label: 'Revenue',
      fill: true,
      backgroundColor: ["#4ecc48", "#ffcc29"],
      data: [498, 0]
    },
  ]
}

const doughnutOptions = {
  legend: {
    display: false,
  },
}

const chartOptions = {
  fill: true,
  borderJoinStyle:"miter",
  scales: {
    xAxes: [{
      gridLines: {
        display: true,
      },
      ticks: {
        display: true,
      }
    }],
    yAxes: [{
      gridLines: {
          display: true,
      },
      ticks: {
        display: true,
      }
    }]
  },
  legend: {
    display: false,
  },
}

function generateData(count: number, start: number = 0) {
  const data = Array(count).fill(0);
  for (let i in data) {
    data[i] = randomScalingFactor(start);
  }
  data.unshift(0, 0, 0, 0, 0, 0, 0, 0);
  return data;
}

function randomScalingFactor(start: number) {
  return Math.round(start + Math.random() * 30);
};

export function DashboardRevenue() {
  
  const { t } = useTranslation('dashboard');

  return (
  <div className="row content-box">
    <div className="element-wrapper col-md-6 col-sm-12">
      <div className="element-box">
        <div className="os-tabs-w">
          <div className="tab-content">
            <div className="tab-pane active" id="tab_overview">
              <div className="el-chart-w">
                <div className="centered-header"><h6>{t("revenue.today")}</h6></div>
                <Bar data={mainChartData} options={chartOptions} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-6 col-sm-12">
      <div className="row">
        <div className="element-wrapper col-md-6 col-sm-12">
          <div className="element-box">
            <div className="centered-header"><h6>{t('revenue.zoneBreakdown')}</h6></div>
            <Doughnut data={zoneChartData} options={doughnutOptions} />
          </div>
        </div>
        <div className="element-wrapper col-md-6 col-sm-12">
          <div className="element-box">
            <div className="centered-header"><h6>{t('revenue.serviceBreakdown')}</h6></div>
            <Doughnut data={serviceChartData} options={doughnutOptions} />
          </div>
        </div>
        <div className="element-wrapper col-md-6 col-sm-12">
          <div className="element-box">
            <div className="centered-header"><h6>{t('revenue.channelBreakdown')}</h6></div>
            <Doughnut data={channelChartData} options={doughnutOptions} />
          </div>
        </div>
        <div className="element-wrapper col-md-6 col-sm-12">
          <div className="element-box">
            <div className="centered-header"><h6>{t('revenue.typeBreakdown')}</h6></div>
            <Doughnut data={typeChartData} options={doughnutOptions} />
          </div>
        </div>
      </div>
    </div>
  </div>
  );

}

import { RouteProps } from "react-router-dom"; 
import { TrackVehicle } from "./track.component"; 

export enum SecurityRoute { 
  TrackVehicle = '/track', 
}

export const SecurityRouteMap = [
  {
    path: SecurityRoute.TrackVehicle,
    component: TrackVehicle,
  },
   
] as RouteProps[];
import classNames from 'classnames';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps, withRouter } from "react-router-dom"; 
import { MenuItem, SubItem } from "./header.interface";
import { AuthService } from "../../services/auth.service";
import { User } from "../../entities/user.entity";
import { Role, RoleAssignment } from "../../entities/role.entity";
import Menu from './menu.data';

const ImgLogo = require(`../../img/${process.env.REACT_APP_LOGO_MINI}`);

function HeaderComponent({ location }: RouteComponentProps) {

  const [menu, setMenu] = useState<Array<MenuItem>>(Menu);

  const [mobDisplay, setMobDisplay] = useState(false);

  const { t } = useTranslation('main');

  const user: User | undefined = AuthService.getUser();

  const assignments: RoleAssignment[] = user ? [...((user?.role as Role).assignments as RoleAssignment[])] : [];
  const moduleCodes = Array.from(assignments, assignment => ({
    module: assignment.module,
    action: assignment.action
  }));

  useEffect(autoSelect, [location]);

  function autoSelect() {
    const link = location.pathname;
    const currentMenu = menu.find(item =>
      item.link === link || item.submenu?.find(submenu =>
        submenu.find(subsubmenu => subsubmenu.link === link))
    );
    if (currentMenu) {
      toggle(currentMenu, 'selected', true);
    } else {
      toggle(menu[0], 'selected', true);
    }
  }

  function toggle(item: MenuItem, property: 'active' | 'selected', value: boolean) {
    const _menu = [...menu];
    _menu.forEach(_m => _m[property] = false);
    const i = _menu.findIndex(m => m.title === item.title);
    if (i !== -1) {
      _menu[i][property] = value;
      setMenu(_menu);
    }
  }

  function subMenuToggle(item: MenuItem, property: 'active' | 'selected', value: boolean) {
    const _menu = [...menu];
    _menu.forEach(_m => _m[property] = false);
    const i = _menu.findIndex(m => m.title === item.title);
    if (i !== -1) {
      _menu[i][property] = value;
      setMenu(_menu);
      setMobDisplay(false);
    }
  }

  function subToggle(item: MenuItem, property: 'active' | 'selected', value: boolean, itemdisplay: string | undefined) {
    const _menu = [...menu];
    _menu.forEach(_m => _m[property] = false);
    const i = _menu.findIndex(m => m.title === item.title);
    if (i !== -1) {
      _menu[i][property] = value;
      setMenu(_menu);

      if (!(item.submenu == undefined)) {
        if (itemdisplay == undefined) {
          item.display = "block";
        }
        else if (item.display == "block") {
          item.display = "";
        }
        else {
          item.display = "block";
        }
      }
      //  const j= document.getElementById(item.title);
      //  if(!(item.submenu==undefined)){
      //  if(!(j?.classList.contains('d-block')))
      //  {j?.classList.add('d-block');}
      //  else{
      //    j?.classList.remove('d-block');
      //  }
      // }
    }
  }

  const showItem = (item: MenuItem) => {
    if (typeof item.module === "string") {
      return !!moduleCodes.find(singularity => item.isPublic || item.module === singularity.module);
    } else {
      return item.module.filter(mod => moduleCodes.find(singularity => mod === singularity.module)).length > 0;
    }
  }

  const showSubItem = (subitem: SubItem) => {
    if (subitem.code === undefined) {
      return true;
    } else if (typeof subitem.code === "string") {
      return !!moduleCodes.find(singularity => subitem.module === singularity.module && subitem.code === singularity.action);
    }
    else {
      return subitem.code.filter(singleCode => moduleCodes.find(singularity => singleCode === singularity.action && subitem.module === singularity.module)).length > 0;
    }
  }

  const Mobmenu = () => {
    if (mobDisplay == true) {
      setMobDisplay(false);
    }
    if (mobDisplay == false) {
      setMobDisplay(true);
    }

  }

  return (
    <div>
      <div className="menu-mobile menu-activated-on-click color-scheme-dark">
        <div className="mm-logo-buttons-w">
          <a className="mm-logo" href="#logo">
            <img src={ImgLogo} alt="LinkMi" />
            <span>Admin</span>
          </a>
          <div className="mm-buttons">
            <div className="content-panel-open">
              <div className="os-icon os-icon-grid-circles"></div>
            </div>
            <div className="mobile-menu-trigger">
              <div className="os-icon os-icon-hamburger-menu-1" onClick={Mobmenu} ></div>
            </div>
          </div>
        </div>
        {mobDisplay ?
          <div className="menu-and-user" style={{ display: 'block' }}>
            <ul className="main-menu">
              {menu.map((item: MenuItem) => (
                showItem(item) && (

                  <li key={item.title} className={classNames({ 'has-sub-menu': !!item.submenu })}>
                    <Link to={item.link} onClick={() => subToggle(item, 'selected', true, item.display)}>
                      <div className="icon-w">
                        <div className={`os-icon os-icon-${item.icon}`}></div>
                      </div>
                      <span>{item.title}</span>
                    </Link>

                    <ul className="sub-menu" style={{ display: item.display }} id={item.title}>
                      {item.submenu?.map(submenu => submenu.map(subitem => (
                        showSubItem(subitem) && (
                          <li key={subitem.link}>
                            <Link to={subitem.link} onClick={() => subMenuToggle(item, 'selected', true)}>
                              {subitem.title}
                              {subitem.badge && <strong className="badge badge-danger">{subitem.badge}</strong>}
                            </Link>
                          </li>
                        )
                      )))}
                    </ul>
                  </li>
                )
              ))}
            </ul>
          </div> : null
        }
      </div>
      <div className="menu-w color-scheme-dark color-style-default menu-position-top menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-bright menu-activated-on-hover menu-has-selected-link">
        <div className="logo-w">
          <a className="logo" href="#logo">
            <div className="logo-element">
              <img src={ImgLogo} alt="LinkMi" />
            </div>
            <div className="logo-label">Admin</div>
          </a>
        </div>

        <ul className="main-menu">
          {menu.map((item: MenuItem) => (
            showItem(item) && (
              <li key={item.title} className={classNames({
                'has-sub-menu': !!item.submenu,
                selected: item.selected,
                active: item.active,
              })}
                onMouseEnter={() => toggle(item, 'active', true)}
                onMouseLeave={() => toggle(item, 'active', false)}
              >
                <Link to={item.link} onClick={() => toggle(item, 'selected', true)}>
                  <div className="icon-w">
                    <div className={`os-icon os-icon-${item.icon}`}></div>
                  </div>
                  <span>{t(item.title)}</span>
                </Link>
                <div className="sub-menu-w">
                  <div className="sub-menu-header">${item.title}</div>
                  <div className="sub-menu-icon">
                    <i className={`os-icon os-icon-${item.icon}`}></i>
                  </div>
                  <div className="sub-menu-i">
                    {item.submenu?.map((submenu, i) =>
                      <ul key={i} className="sub-menu">
                        {submenu.map(subitem => (
                          showSubItem(subitem) && (
                            <li key={subitem.link} className={subitem.selected ? 'selected' : ''}>
                              <Link to={subitem.link} onClick={() => toggle(item, 'selected', true)}>
                                {t(subitem.title)}
                                {subitem.badge && <strong className="badge badge-danger">{subitem.badge}</strong>}
                              </Link>
                            </li>
                          )
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </li>
            )
          ))}
        </ul>
      </div>
    </div>
  );
}

export const Header = withRouter(HeaderComponent);
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enAcl from '../locales/en/acl.json';
import enDashboard from '../locales/en/dashboard.json';
import enForms from '../locales/en/forms.json';
import enLists from '../locales/en/list.json';
import enTranslation from '../locales/en/main.json';
import enSettings from '../locales/en/settings.json';
import esAcl from '../locales/es/acl.json';
import esDashboard from '../locales/es/dashboard.json';
import esForms from '../locales/es/forms.json';
import esLists from '../locales/es/list.json';
import esTranslation from '../locales/es/main.json';
import esSettings from '../locales/es/settings.json';

const language = ['en', 'es'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    whitelist: language,
    debug: true,
    resources: {
      en: {
        main: enTranslation,
        dashboard: enDashboard,
        list: enLists,
        acl: enAcl,
        forms: enForms,
        settings: enSettings,
      },
      es: {
        main: esTranslation,
        dashboard: esDashboard,
        list: esLists,
        acl: esAcl,
        forms: esForms,
        settings: esSettings,
      },
    }, 
  });

export default i18n;

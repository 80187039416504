import React from "react";

interface Activity {
  route: string
  type: string
  estimate: string
  timestamp: string
}

interface Props {
  title: string
  activities: Array<Activity>
  color: string
}

export function DashboardEngagingActivity(props: Props) {
  return (
    <div className="element-wrapper">
      <div className="element-box">
        <h6 className="element-header">
          <span className={ `badge badge-${ props.color }` }>{ props.activities.length }</span> &nbsp;
          { props.title } 
        </h6>
        <table className="table table-clean">
          <tbody>
          { props.activities.map(activity => (
            <tr>
              <td>
                <div className="value">{ activity.route }</div>
                <span className="sub-value">{ activity.type }</span>
              </td>
              <td className="text-right">
                <div className="value">${ activity.estimate }</div>
                <span className="sub-value">{ activity.timestamp }</span>
              </td>
            </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
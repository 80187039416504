import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Dropzone, { DropzoneRef, FileRejection } from "react-dropzone";
import { UseFormMethods, ValidationRules } from 'react-hook-form';

export interface UploadPreviewUrl {
  thumb: string;
  full: string;
};

interface Props {
  label: string;
  name: string;
  originalName: string;
  urls?: UploadPreviewUrl[];
  description?: string;
  multiple?: boolean;
  validation: ValidationRules;
  className?: any;
  autoFocus?: boolean;
  tabIndex?: number;
}

interface DropzoneFile extends File {
  dataUrl?: string;
}

export function FormUpload(props: Props & UseFormMethods) {

  let expandedImgRef!: HTMLImageElement | null;
  let dropzone = useRef<DropzoneRef>(null);

  const {
    name, originalName, label, urls, description, multiple, className, register, errors,
    setError, setValue, tabIndex } = props;
  const [ files, setFiles ] = useState<DropzoneFile[]>([]);
  const [ expanded, setExpanded ] = useState<string>();

  useEffect(() => register({ name }), [register, name]);
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (expandedImgRef !== event?.currentTarget) {
        setExpanded(undefined);
      }
    }
    document.addEventListener('mousedown', listener);
    return () => { document.removeEventListener('mousedown', listener); }
  }, [expandedImgRef]);

  const onDropped = (_files: File[]) => {
    const __files: DropzoneFile[] = files.concat(_files);
    for (let __file of __files) {
      __file.dataUrl = __file.dataUrl || URL.createObjectURL(__file);
    }
    setFiles(__files);
    setValue(name, multiple ? __files : __files[0]);
  }

  const onError = (rejections: FileRejection[]) => {
    const [{ errors }] = rejections;
    const [error] = errors;
    setError(name, { type: error.code, message: error.message });
  }

  const onClick = (url: string) => {
    if (multiple) {
      setExpanded(url);
    } else {
      dropzone.current?.open();
    }
  }

  const hideDropzone = () => !multiple && files.length > 0;
  const hasPreview = () => files.length > 0 || (urls && urls.length > 0);

  return (
    <div className={classNames( 
        {
          'form-upload': true,
          multiple: multiple,
          single: !multiple, 
          'has-error': errors[name],
        },
        className,
      )} tabIndex={tabIndex}>
      { expanded && (
      <div>
          <img
          ref={r => expandedImgRef = r}
          className="expanded-img"
          src={ expanded }
          alt={ label }
        />
        <div className="expanded-backdrop"></div>
      </div>)}
      { hasPreview() && (<div className="preview-container">
        {urls?.map((url, i) => (
          <img
            key={i} 
            src={url.thumb}
            className="preview" 
            alt={ label }
            onClick={() => onClick(url.full)}
          />
        ))}
        {files.map((file, i) => (
          <img
            key={i} 
            src={file.dataUrl} 
            className="preview" 
            alt={ label }
            onClick={() => onClick(file.dataUrl as string)}
          />
        ))}
      </div>
      )}
      <Dropzone
        ref={ dropzone }
        accept="image/*"
        multiple={multiple}
        maxSize={5*1024*1024}
        onDropRejected={onError}
        onDrop={onDropped}>
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps({ className: classNames(
            'upload-dropzone',
            { 'hidden': hideDropzone() },
            { 'has-preview': hasPreview() },
            )})}>
            <input ref={register} name={name} {...getInputProps()} tabIndex={tabIndex} />
            <h3>{ label }</h3>
            { description && <p>{ description }</p> }
            { errors[name] && <p className="error-message">{ errors[name].message }</p> }
          </div>
        )}
      </Dropzone>

      { multiple ? urls?.map((_, i) => (
        <>
          <input ref={ register } type="hidden" name={`${originalName}[${i}].id`} />
          <input ref={ register } type="hidden" name={`${originalName}[${i}].extension`} />
          <input ref={ register } type="hidden" name={`${originalName}[${i}].type`} />
        </>
      )) : (
        <>
          <input ref={ register } type="hidden" name={`${originalName}.id`} />
          <input ref={ register } type="hidden" name={`${originalName}.extension`} />
          <input ref={ register } type="hidden" name={`${originalName}.type`} />
        </>
      )}
    </div>
  );
}

import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

interface Props {
  title: string;
  subtitle?: string;
  imgSrc?: string;
  className?: any;
  childClassName?: any;
  wrapperClassName?: any;
  headerClassName?: any;
}

export function ViewContent({
  title, subtitle, imgSrc, children, childClassName, className, headerClassName
}: PropsWithChildren<Props>) {
  return (
    <div className={ classNames(className) }>
      <div className="ecommerce-customer-info">
        <div className={ classNames('ecommerce-customer-main-info', headerClassName) }>
          { imgSrc && <div className="ecc-avatar"><img src={ imgSrc } alt={ title } /></div> }
          <div className="ecc-name">{ title }</div>
          { subtitle && <p>{ subtitle }</p> }
        </div>
        <div className={ classNames(childClassName) }>
          { children }
        </div>
      </div>
    </div>
  );
}

interface IContentWrapper {
  className?: any;
}

export function ContentWrapper({
  className, children
}: PropsWithChildren<IContentWrapper>) {
  return (
    <div className={ classNames('m-b', className) }>
      { children }
    </div>
  )
}
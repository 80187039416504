import { RouteProps } from "react-router-dom";  
import { DeviceList } from "./list.component";
import { DevicePost } from "./post.component";

export enum DeviceRoute {
  Post = '/device/post',
  List = '/device/list'
}

export const DeviceRouteMap = [
  {
    path: DeviceRoute.List,
    component: DeviceList,
  },
  {
    path: DeviceRoute.Post,
    component: DevicePost,
  }
] as RouteProps[];

import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { Api } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { AuthRoute } from './routes';

/**
 * Private routes are only accessible to logged-in users
 * Non-logged-in users will be redirected to login page
 */
export function PrivateRoute({ component: Component, children, ...props }: any) {
  const [value, setValue] = useState(props.loading);
  // This will launch only if propName value has chaged.
  useEffect(() => {
    setValue(props.loading)
  }, [props.loading]);
  if (!Component) return null;

  const renderRoute = (props: RouteComponentProps & RouteProps & any) => {
    if (!Component) {
      return props.children;
    } else {
      return (
        !props.loading && <Component
          path={props.path}
          location={props.location} />
      );
    }
  }
  return (
    <Route
      path={props.path}
      location={props.location}
      render={({ location, history, match }) =>
        AuthService.isLoggedIn() ?
          renderRoute({ history, match, location, ...props }
          ) : (
            <Redirect
              to={{
                pathname: AuthRoute.Login,
                state: { from: location }
              }}
            />
          )
      }
    />
  )
}
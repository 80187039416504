import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/form.component";
import { FormHeader } from "../../../components/form/header.component";
import { FormSections, SectionsWrapper } from "../../../components/form/sections.component";
import { Vehicle } from "../../../entities/vehicle.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormHeaderType, FormStyle } from "../../../enums/form.enum";
import { Endpoint } from '../../../services/api.service';
import { VehicleRoute } from "../routes"; 
import { VehiclePostModel } from "./section/model.component";
import { VehiclePostRegistration } from "./section/registration.component"; 


// export interface VehicleType {
//   id: string;
//   name: string;
// }

// export const vehicleTypes = [
//   { name: 'Hail', id: 'hail '},
//   { name: 'App', id: 'app '},
//   { name: 'Dispatch', id: 'dispatch '},
// ] as VehicleType[]

interface Props {
  style?: FormStyle;
  cancel?: Function;
  formMode?: FormMode;
  entityId?: string;
  onAfterSave?: (entity: Vehicle) => void;
}

/**
 * Add or update a vehicle
 */
export function VehiclePost({
  style, cancel, formMode, entityId, onAfterSave,
}: Props) {

  const { t } = useTranslation('main');

    /**
   * Custom Header
   */
  const header = () => (
    style && style === FormStyle.Containerized ? (
      <>
        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Title } title={ t("entities.vehicle") } />
        </SectionsWrapper>

        <SectionsWrapper className="col-lg-6">
          <FormHeader type={ FormHeaderType.Controls } />
        </SectionsWrapper>
      </>
    ) : (
      <FormHeader type={ FormHeaderType.Title } title={ t("entities.vehicle") } />
    )
  );

  /**
   * Render
   */
  return (
    <Form<Vehicle>
      endpoint={ Endpoint.VEHICLE }
      returnLink={ VehicleRoute.List }
      cancel={ cancel }
      formMode={ formMode }
      entityId={ entityId }
      onAfterSave={ onAfterSave }
      className={ style === FormStyle.Containerized && "ae-content-w" }
      fileEndpoint={ Endpoint.VEHICLE_FILES }
      formFiles={[
        { required: true, count: 1, originalName: 'plateFile', name: "newPlateFile", desc: t("vehicle.registrationSection.platePictureLabel") },
        { required: false, count: 4, originalName: 'profileFiles', name: "newProfileFiles", desc: t("vehicle.modelSection.vehiclePictureLabel") },
        { required: false, count: 4, originalName: 'insuranceFiles', name: "newInsuranceFiles", desc: t("vehicle.modelSection.insurancePictureLabel") },
      ]}
    >
      { style === FormStyle.Containerized && header() }
      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Title } title={ t("entities.vehicle") } /> }
        <FormSections>
          <VehiclePostRegistration />
          <VehiclePostModel />
        </FormSections>
      </SectionsWrapper>

      <SectionsWrapper className={ style === FormStyle.Containerized ? "col-lg-12" : "" }>
        { style !== FormStyle.Containerized && <FormHeader type={ FormHeaderType.Controls } /> }
        <FormSections> 
        </FormSections>
      </SectionsWrapper>
    </Form>
  );
}

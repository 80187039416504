import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

export interface FileGroup {
  title: string;
  files?: { thumb: string, full: string }[];
}

interface Props {
  groups: FileGroup[];
}

export function ViewContentFiles({ groups }: Props) {

  let expandedImgRef!: HTMLImageElement | null;

  const [ selectedGroup, setSelectedGroup ] = useState<FileGroup>(groups[0]);
  const [ expanded, setExpanded ] = useState<string>();

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (expandedImgRef !== event?.currentTarget) {
        setExpanded(undefined);
      }
    }
    document.addEventListener('mousedown', listener);
    return () => { document.removeEventListener('mousedown', listener); }
  }, [expandedImgRef]);

  const onClick = (url: string) => {
    setExpanded(url);
  }

  return (
    <div>
      <div className="os-tabs-controls">
        <ul className="nav nav-tabs">
          { groups.map(group => (
            <li key={ group.title } className="nav-item">
              <button
                className={ classNames('nav-link', { active: group.title === selectedGroup.title }) }
                data-toggle="tab"
                onClick={ () => { setSelectedGroup( group ); return false } }
              >
                { group.title }
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="d-flex flex-row overflow-auto pb-4">
        
        { selectedGroup.files?.map((file, index) => 
          <img className="mx-2" key={ index } src={ file.thumb } alt={ index.toString() } 
            onClick={() => onClick(file.full)} />
        )}
      </div>
      { expanded && (
      <div>
          <img
          ref={r => expandedImgRef = r}
          className="expanded-img"
          src={ expanded }
          alt = ''
        />
        <div className="expanded-backdrop"></div>
      </div>)}
    </div>
  );
}
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Controller, UseFormMethods, ValidationRules } from 'react-hook-form';
import Select, { OptionsType, ValueType } from 'react-select';
import { getErrorMessage } from '../util';


interface Props<Entity> {
  label: string;
  name: string;
  labelField?: string;
  valueField?: string;
  items: Entity[] | undefined,
  className?: any;
  validation: ValidationRules;
  showLabel?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  loading?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  onChange?: (value: Entity | Entity[]) => void;
  onInput?: (value: string) => void;
}

interface Option<Entity> {
  label: string;
  value: string;
  ref: Entity,
}

export function FormSelect<Entity>(props: Props<Entity> & UseFormMethods) {
  const { validation, watch, errors, setValue, label, name, items, control,
    showLabel = true, disabled = false, loading = false, multiple = false,
    labelField = 'name', valueField = 'id',
    autoFocus = false, className, onChange: _onChange, onInput: _onInput, tabIndex = 0 } = props;

    const [options, setOptions] = useState<OptionsType<Option<Entity>>>([]);

    useEffect(() => {
      setOptions(
        items ? items.map((i: any) => ({
          label: i[labelField],
          value: i[valueField],
          ref: i,
        })) : []
      );
    }, [ items, labelField, valueField ]);

  const [selectedOption, setSelectedOption] = useState<ValueType<Option<Entity>> | ValueType<Option<Entity[]>>>();

  const fieldValue = watch(name);

  useEffect(() => {
    if (fieldValue) {
      Array.isArray(fieldValue) ? setSelectedOption(
        fieldValue.map(i => ({
          value: (i as any)[valueField],
          label: (i as any)[labelField],
          ref: i
        }))
      ) : setSelectedOption({
        value: (fieldValue as any)[valueField],
        label: (fieldValue as any)[labelField],
        ref: fieldValue,
      });
    } else {
      setSelectedOption(undefined);
    }
  }, [ fieldValue, valueField, labelField ]);

  function onInput(value: string) {
    if (_onInput) {
     _onInput(value);
    }
  }

  function onChange(value: Option<Entity> | Option<Entity[]>) {
    setValue(name, Array.isArray(value) ? value.map(i => i.ref) : value?.ref);
    if (_onChange) {
      _onChange(value?.ref);
    }
  }

  return (
    <div className={ classNames('form-group', { 'has-danger': errors && errors[name] }, className) }>
      { showLabel && <label className={validation.required ? 'required' : ''}>{ label }</label> }
      <Controller
        control={ control }
        name={ name }
        rules={ validation }
        defaultValue={ null }
        render={ () =>
          <Select 
            placeholder={ label }
            backspaceRemovesValue={ multiple }
            isClearable
            isSearchable
            hideSelectedOptions={ multiple }
            value={ selectedOption }
            isDisabled={ disabled }
            isLoading={ loading }
            isMulti={ multiple }
            options={ options }
            closeMenuOnSelect={ !multiple }
            escapeClearsValue={ false }
            autoFocus={ autoFocus }
            noOptionsMessage={ () => "No items to show" }
            tabIndex={ tabIndex?.toString() }
            onChange={ (value) => onChange(value as any) }
            onInputChange={ (value) => onInput(value) }
          />
        }
      />

      {errors && errors[name] && (
      <div className="help-block form-text with-errors form-control-feedback">
        <ul className="list-unstyled"><li>{ getErrorMessage(errors[name], label) }</li></ul>
      </div>
      )}
    </div>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";
import { Loading } from "../../../components/alerts/loading.component";
import { GroupFilter, ListFilter, StatusFilter } from "../../../components/list/filter.component";
import { ListItem, ListItems, ListItemStatus } from "../../../components/list/items.component";
import { List } from "../../../components/list/wrapper.component";
import { View } from "../../../components/view/wrapper.component";
import { Vehicle } from "../../../entities/vehicle.entity";
import { FormMode } from "../../../enums/core.enum";
import { FormStyle } from "../../../enums/form.enum";
import { IPaginationOptions, IPaginationResponse } from "../../../interfaces/paginate.interface";
import { Api, Endpoint } from '../../../services/api.service';
import { getStorageUrl } from "../../../util/file.util";
import { VehiclePost } from "../post/post.component";
import { SelectedItemContent } from "./content.component";
import vehicleImg from '../../../img/vehicle.png';

const groupFilters: GroupFilter[] = [
  { title: 'all', icon: 'phone-21' },
  { title: 'pending', icon: 'ui-22' },
  { title: 'new', icon: 'documents-03' },
  { title: "active", icon: "user-check" },
  { title: 'blocked', icon: 'ui-15' },
];

const statusFilters: StatusFilter[] = [
  { title: 'Online', status: ListItemStatus.Green },
  { title: 'Metering', status: ListItemStatus.Blue },
  { title: 'Offline', status: ListItemStatus.Red },
];

export enum ProfilePicSize {
  List = 'admin_list',
  Single = 'admin_single',
}

export function VehicleList() {

  const { t } = useTranslation(['main', 'list']);
  const { y: pageYOffset } = useWindowScroll();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(20);
  const [ count, setCount ] = useState<number>(1);
  const [vehicles, setVehicles] = useState<Vehicle[] | undefined>(undefined);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [, setConfirmingDelete] = useState<boolean>(false);
  const [activeGroupFilter, setActiveGroupFilter] = useState<GroupFilter>(groupFilters[0]);
  const [activeStatusFilter, setActiveStatusFilter] = useState<StatusFilter | undefined>(undefined);

  const refresh = useCallback(async () => {
    let group: string | undefined = activeGroupFilter?.title;
    group = group === 'all' ? undefined : group;
    const status = activeStatusFilter?.title;
    const { items } = await Api.get<IPaginationResponse<Vehicle>, IPaginationOptions>(Endpoint.VEHICLE_LIST, { page: 0, limit, group, status, search }); 

    if (items) {
      setVehicles(items);
    } 

    if (items.length > 0 && !selectedVehicle) {
      setSelectedVehicle(items[0]);
    }
  }, [search, activeGroupFilter, limit, activeStatusFilter, selectedVehicle]);

  useEffect(() => { refresh(); }, [refresh]);

  const handleScroll =useCallback(() =>{
    if (pageYOffset > count * 150) {
      setLimit(limit + 20);
      setCount(count + 1); 
    }    
  },[count, pageYOffset, limit])

  useEffect(()=>{
    window.addEventListener('scroll', handleScroll);
    return(()=>{
      window.removeEventListener('scroll', handleScroll)
    })
  },[pageYOffset, handleScroll])

  const deleteVehicle = async () => {
    setConfirmingDelete(false);
    await Api.delete(Endpoint.VEHICLE, { id: selectedVehicle?.id });
    setSelectedVehicle(undefined);
    refresh();
  }

  const getProfilePic = (vehicle: Vehicle, type: ProfilePicSize): string => {  
    if (vehicle.plateFile)
      return `${getStorageUrl()}/vehicle/${vehicle.id}/plate/${vehicle.plateFile?.id}/${type}.${vehicle.plateFile?.extension}`;
    else {
      return vehicleImg;
    }
  }

  const getModel = (vehicle: Vehicle): string => {
    return [vehicle.year, vehicle.make, vehicle.model].join(' ');
  }

  const toListItems = (vehicle?: Vehicle): ListItem<Vehicle> | undefined => {
    if (!vehicle) {
      return;
    } 
    return {
      id: vehicle.id as string,
      title: vehicle.plate,
      imgSrc: getProfilePic(vehicle, ProfilePicSize.List),
      status: ListItemStatus.Green,
      ref: vehicle
    }
  }

  const addVehicle = () => {
    setFormMode(FormMode.Adding);
  }

  const editVehicle = () => {
    setFormMode(FormMode.Editing);
  }

  const cancelEditing = () => {
    setFormMode(undefined);
  }

  const onNext = () => {
    if (vehicles) {
      const currentVehicleIndex = vehicles.findIndex(vehicle => !!selectedVehicle && vehicle.id === selectedVehicle.id);
      if (currentVehicleIndex + 1 < vehicles.length && currentVehicleIndex !== -1) {
        setSelectedVehicle(vehicles[currentVehicleIndex + 1]);
      }
    }
  }

  const onPrev = () => {
    if (vehicles) {
      const currentVehicleIndex = vehicles.findIndex(vehicle => !!selectedVehicle && vehicle.id === selectedVehicle.id);
      if (currentVehicleIndex - 1 > -1 && currentVehicleIndex !== -1) {
        setSelectedVehicle(vehicles[currentVehicleIndex - 1]);
      }
    }
  }

  const selectGroup = (target: GroupFilter) => {
    setSelectedVehicle(undefined);
    setActiveGroupFilter(target);
  }

  const selectStatus = (target: StatusFilter | undefined) => {
    setSelectedVehicle(undefined);
    setActiveStatusFilter(target?.title !== activeStatusFilter?.title ? target : undefined);
  }

  const onSearchChange = (search: string) => {
    setSearch(search);
  }

  return (
    <List>
      <ListFilter
        groupFilters={groupFilters}
        activeGroupFilter={activeGroupFilter}
        onGroupSelect={selectGroup}
        statusFilters={statusFilters}
        activeStatusFilter={activeStatusFilter}
        onStatusSelect={selectStatus}
      />

      <ListItems
        items={vehicles?.map(toListItems) as ListItem<Vehicle>[]}
        activeItem={toListItems(selectedVehicle)}
        onSearchChange={onSearchChange}
        showSearch={true}
        showRefresh={true}
        onRefresh={() => { setVehicles(undefined); refresh(); }}
        onClick={(item: ListItem<Vehicle>) => { setSelectedVehicle(item.ref); }}
      />

      { formMode === undefined ? (
        vehicles !== undefined ? (
          selectedVehicle ? (
            <View
              showAdd={true}
              showEdit={true}
              showDelete={true}
              showNext={true}
              showPrev={true}
              onAdd={addVehicle}
              onEdit={editVehicle}
              onDelete={deleteVehicle}
              onPrev={onPrev}
              onNext={onNext}
            >
              <SelectedItemContent
                selectedVehicle={selectedVehicle}
                getModel={getModel}
                getProfilePic={getProfilePic}
              />
            </View>
          ) : (
              <div className="mx-auto py-5 text-center">
                <button onClick={addVehicle} className="btn btn-primary text-bold">{t("mainMenu.fleet.addVehicle")}</button>
              </div>
            )
        ) : (
            <div className="mx-auto py-5 text-center">
              <p>{t("mainMenu.fleet.wait")}</p>
              <Loading loading={true} />
            </div>
          )
      ) : (
          selectedVehicle ? (
            <VehiclePost
              style={FormStyle.Containerized}
              formMode={formMode}
              cancel={cancelEditing}
              entityId={selectedVehicle.id}
              onAfterSave={() => refresh()}
            />
          ) : (
              <VehiclePost
                style={FormStyle.Containerized}
                formMode={formMode}
                cancel={cancelEditing}
                onAfterSave={() => refresh()}
              />
            )
        )}
    </List>
  );

}

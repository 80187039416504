import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../../components/form/input/input.component";
import { FormUpload } from "../../../../components/form/input/upload.component";
import { FormSection } from "../../../../components/form/section.component";

export function VehiclePostModel() {

  const { t } = useTranslation('forms');
  const formProps = useFormContext();

  return (
    <FormSection
      title={t("vehicle.modelSection.title")}
      subtitle={t("vehicle.modelSection.subTitle")}
    >
      <div className="row">
        <FormInput
          label={t("vehicle.modelSection.year")}
          name="year"
          type="number"
          className="col-sm-4"
          validation={{ required: true, min: 1980, max: 2021 }}
          tabIndex={5}
          {...formProps}
        />
        <FormInput
          label={t("vehicle.modelSection.make")}
          name="make"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={6}
          {...formProps}
        />
        <FormInput
          label={t("vehicle.modelSection.model")}
          name="model"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={7}
          {...formProps}
        />
      </div>
      <div>
      </div>
      <div className="row">
        <FormInput
          label={t("vehicle.modelSection.color")}
          name="color"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={8}
          {...formProps}
        />
        <FormInput
          label={t("vehicle.modelSection.numberOfPassenger")}
          name="numPassengers"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={9}
          {...formProps}
        />
        <FormInput
          label={t("vehicle.modelSection.numberOfLuggages")}
          name="numLuggage"
          type="text"
          className="col-sm-4"
          validation={{ required: true }}
          tabIndex={10}
          {...formProps}
        />
      </div>
      <div className="row">
        <FormUpload
          label={t("vehicle.modelSection.vehiclePictureLabel")}
          name="newProfileFiles"
          originalName="profileFiles"
          description={t("vehicle.modelSection.vehiclePictureDescription")}
          className="col-md-12"
          multiple={true}
          validation={{ required: true }}
          tabIndex={11}
          {...formProps}
        />
      </div>
      {/* <div className="row">
        <p>The following policies are required in order for us to approve your vehicle:
          <br />- Comprehensive
          <br />- CTP
          <br />- Workers Compensation
          <br />
          <br />The following policy is recommended, though optional:
          <br />- Public Liability</p>
        <FormUpload
          label={t("vehicle.modelSection.insurancePictureLabel")}
          name="newInsuranceFiles"
          originalName="insuranceFiles"
          description={t("vehicle.modelSection.insurancePictureDescription")}
          className="col-md-12"
          multiple={true}
          validation={{ required: true }}
          tabIndex={12}
          {...formProps}
        />

      </div> */}
    </FormSection>
  );

}

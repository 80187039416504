import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { FormInput } from "../../components/form/input/input.component";
import { NavLink } from "react-router-dom";
import { Api, ApiType, Endpoint } from "../../services/api.service";
import { DashboardRoute } from "../dashboard/routes";
import { Loading } from "../../components/alerts/loading.component";

const logo = require(`../../img/${process.env.REACT_APP_LOGO}`);

interface ForgotPasswordRequest {
  email: string;
}

/**
 * Forgot screen
 */
export function AuthForgot({ history }: RouteComponentProps) {

  const form = useForm();
  const childProps = { ...form };
  
  const { setError, handleSubmit } = form;

  const { t } = useTranslation("main");
  const [processing, setProcessing] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<boolean>(false);
  const [, setErrorMessage] = useState<string | null>();

  async function save({ email }: ForgotPasswordRequest) {
    setErrorMessage(null);
    setProcessing(true);
console.log('Email is this:',email);
    try {
      const data = await Api.post<Boolean, ForgotPasswordRequest>(
        Endpoint.Forgot_Password,
        { email },
        ApiType.Rest
      );
      console.log('Response after Request: ',data)
      if(data){
        setProcessing(false);
        setResponseData(true)
      }
      else{
        setProcessing(false);
      setError("email", { type: "auth", message: "Email address not registered" });
      }
    } catch (err) {
      setError("email", { type: "auth", message: "something went wrong..." });
      setErrorMessage(err.message);
      console.log("Email Error", err);
      setProcessing(false);
    }
  }

  return (
    <div className="row">
      <div className="auth-subcontainer left col-md-6">
        <img src={logo} className="logo" alt="Logo" />
        <div className="warning">
          <h2>{t("auth.login.warning")}</h2>
          <p>{t("auth.login.warningMsg")}</p>
        </div>
      </div>
      <div className="auth-subcontainer right col-md-6">
      { !responseData? <form className="row" 
        onSubmit={handleSubmit((form) => save(form as ForgotPasswordRequest))}
          onChange={() => setErrorMessage(null)}>
          <fieldset className="form-group">
            <FormInput
              name="email"
              label={t("auth.login.email")}
              type="email"
              autoFocus
              tabIndex={1}
              validation={{ required: true }}
              {...childProps}
            />
{!processing ? <>
            <div className="buttons-w">
              <button className="btn btn-primary" type="submit">
                {t("auth.login.submit")}
              </button>
            </div>
            <div
              style={{
                marginTop: "10px",
                textDecoration: "underline",
              }}
            >
              <NavLink activeClassName="active" to="/auth/login">
                {t("auth.login.recover-password")}
              </NavLink>
            </div></> : <Loading loading={processing}/>
            }
          </fieldset>
        </form> : 
        <div style={{marginTop:'70px',marginBottom:'10px'}}>
          <h2>Email sent succesfully!</h2>
          <NavLink activeClassName="active" to="/auth/login">
                {t("auth.login.recover-password")}
              </NavLink>
        </div> 
          }
      </div>
    </div>
  );
}

import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FormMode } from '../../enums/core.enum';
import { FormHeaderType } from '../../enums/form.enum';
import { DashboardRoute } from '../../pages/dashboard/routes';
import { Loading } from '../alerts/loading.component';
import { FormPropsContext } from './form.component';

/*  The props that the header component expects to be passed to it 
    type (required): Either be Title or Options. Title header uses the title passed as the prop to show heading.
        Controls header shows the save and cancel controls 
    title (optional): The title that'll be shown on top of the form
    className (optional): Needed if additional styling needs to be provided */
interface Props {
    type: FormHeaderType;
    title?: string;
    className?: any;
    fullTitle?: boolean;
}

/* The FormHeader component */
export function FormHeader({
    type, title, className, fullTitle
}: Props) {
    /* The FormPropsContext provides the required properties to the header */
    const context = useContext(FormPropsContext);
    const { returnLink, errorMessage, cancel, saving, loading, mode } = context;

    /* The useTranslation hook */
    const { t } = useTranslation('main');

    /* The getHeading function */
    const getHeading = () => {
        /* Return the loading message if the loading state provided by the form component is true */
        if (loading) {
            return t("form.header.loading");
        }

        if(fullTitle) {
            return (title);
        }
        /* Otherwise, show the form header based on the title prop provided to it */
        return mode === FormMode.Adding ? t("form.header.newTitle", { what: title }) : t("form.header.updateTitle", { what: title });
    }
    
    /* Return the header based on the type provided to it */
    return type === FormHeaderType.Title ?
        /* The title header */
        <h6 className={ classNames("element-header", className) }>
            <Loading loading={ !!loading } />
            { getHeading() }
        </h6> : 
        /* The controls header */
        <h6 className={ classNames("element-header", className) }>
            <Loading loading={ !!loading } className="invisible" />

            <div className="form-actions">
                {/* In case of error message, show it */}
                { errorMessage && (<span className="form-error">{ errorMessage }</span>)}

                {/* The return link if one decides to cancel the form */}
                { !cancel ? (
                    <Link to={ returnLink ? returnLink : DashboardRoute.Overview } className="btn btn-primary-outline" type="button">{ t("form.controls.cancel") }</Link>
                ) : (
                    <button onClick={ () => { cancel(); } } className="btn btn-primary-outline" type="button">{ t("form.controls.cancel") }</button>
                ) }
                
                {/* If no error message and form isn't in saving condition, show the save button */}
                { !saving && !errorMessage && <button className="btn btn-success" type="submit">{ t("form.controls.save") }</button> }
                
                {/* Show the Saving... button if the form is in saving condition */}
                { saving &&                   <button className="btn btn-primary" disabled type="button">{ t("form.controls.saving") }</button> }
                
                {/* In case of error, disable the save button */}
                { errorMessage &&             <button className="btn btn-danger" disabled type="button" title={ errorMessage }>{ t("form.controls.error")}</button> }
            </div>
        </h6>;
}
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { Loading } from "../../components/alerts/loading.component";
import { FormInput } from "../../components/form/input/input.component";
import { User } from "../../entities/user.entity";
import { Api, ApiType, Endpoint } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";
import { DashboardRoute } from "../dashboard/routes";
import { NavLink } from "react-router-dom";
import { ChatService } from "../../services/chat.service";

const logo = require(`../../img/${process.env.REACT_APP_LOGO}`);

interface LoginRequest {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface LoginResponse {
  accessToken: string;
  chatToken: string;
  user: User;
}

/**
 * Login screen
 */
export function AuthLogin({ history }: RouteComponentProps) {
  const [processing, setProcessing] = useState<boolean>(false);
  const [, setErrorMessage] = useState<string | null>();

  const form = useForm();
  const { setError, handleSubmit } = form;
  const childProps = { ...form };

  const { t } = useTranslation("main");

  async function save({ email, password, rememberMe }: LoginRequest) {
    setErrorMessage(null);
    setProcessing(true);

    try {
      const { accessToken, chatToken, user } = await Api.post<LoginResponse, LoginRequest>(
        Endpoint.LOGIN,
        { email, password, rememberMe },
        ApiType.Rest
      );

      AuthService.setAccessToken(accessToken, rememberMe);
      AuthService.setUser(user);
      ChatService.setChatToken(chatToken, rememberMe);
      setProcessing(false);
      history.push(DashboardRoute.Overview);
    } catch (err:any) {
      setError("email", { type: "auth", message: "Invalid credentials" });
      setError("password", { type: "auth", message: "Invalid credentials" });
      setErrorMessage(err.message);
      console.log("Login Error", err);
      setProcessing(false);
    }
  }

  return (
    <div className="row">
      {AuthService.isLoggedIn() && <Redirect to="/" />}
      <div className="auth-subcontainer left col-md-6">
        <img src={logo} className="logo" alt="Logo" />
        <div className="warning">
          <h2>{t("auth.login.warning")}</h2>
          <p>{t("auth.login.warningMsg")}</p>
        </div>
      </div>
      <div className="auth-subcontainer right col-md-6">
        <form
          className="row"
          onSubmit={handleSubmit((form) => save(form as LoginRequest))}
          onChange={() => setErrorMessage(null)}
        >
          <fieldset className="form-group">
            <legend>
              <span>{process.env.REACT_APP_NAME}</span>
            </legend>
            <div className="form-desc">{t("auth.login.loginMsg")}</div>
            <FormInput
              label={t("auth.login.email")}
              name="email"
              type="email"
              autoFocus
              tabIndex={1}
              validation={{ required: true }}
              {...childProps}
            />
            <FormInput
              label={t("auth.login.password")}
              name="password"
              type="password"
              tabIndex={2}
              validation={{ required: true }}
              {...childProps}
            />
            <div
              style={{
                marginBottom: "10px",
                textDecoration: "underline",
              }}
            >
              <NavLink activeClassName="active" to="/auth/forgot">
                {t("auth.login.forgot-password")}
              </NavLink>
            </div>
            {!processing ? (
              <div className="buttons-w" >
                <button className="btn btn-primary" type="submit">
                  {t("auth.login.title")}
                </button>

                <div className="form-check-inline">
                  <label className="form-check-label" style={{ marginTop: "-22px", marginRight: '5px' }}>
                    {t("auth.login.remeber-me")}
                  </label>
                  <FormInput
                    name="rememberMe"
                    label=""
                    type="checkbox"
                    className="form-check-input"
                    validation={{ required: false }}
                    {...childProps}
                  />

                </div>
              </div>

            ) : (
                <Loading loading={processing} />
              )}
          </fieldset>
        </form>
      </div>
    </div>
  );
}

import classNames from 'classnames';
import React, { PropsWithChildren, useState } from 'react'; 
import { UserStatus } from '../../entities/user.entity';

interface Props { 
  changeUserStatus?: UserStatus; 
  showEdit?: boolean;
  showDelete?: boolean;
  showNext?: boolean;
  showPrev?: boolean;
  showAdd?: boolean;
  wrapper?: boolean;
  showStateChange?: boolean;
  className?: any;
  childClassName?: any;

  onAdd?: () => void
  onEdit?: () => void
  onDelete?: () => void
  onStateChange?: () => void
  onNext?: () => void
  onPrev?: () => void
}

export function View({
  showEdit, showDelete, className, children, showStateChange, changeUserStatus,
  childClassName, onDelete, onNext, onPrev, onEdit, showAdd, onAdd, onStateChange,
}: PropsWithChildren<Props>) {

  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);

  return (
    <div className={classNames('ae-content-w', className)}>
      { (showEdit || showDelete || showAdd || showStateChange) && (
        <div className="aec-head">
          <div className="actions-left">
          </div>
          <div className="actions-right">
            <div className="aeh-actions">
              {showAdd && (
                <a href="#add" onClick={onAdd}><i className="os-icon os-icon-plus"></i></a>
              )}
              {showEdit && onEdit && (
                <a href="#edit" onClick={onEdit}><i className="os-icon os-icon-pencil-1"></i></a>
              )}
              {/* {showStateChange && onStateChange && ( */}
                <a href="#state-change" onClick={onStateChange}>
                  {/* {changeDriverStatus === DriverStatus.Active ?
                    <i className="os-icon os-icon-user-check"></i>
                    : changeDriverStatus === undefined ?
                      <i className=""></i>
                      : <i className="os-icon os-icon-user-x"></i>
                  } */}
                </a>
              {/* )} */}
              {showStateChange && onStateChange && changeUserStatus && (
                <a href="#state-change" onClick={onStateChange}>
                  {changeUserStatus === UserStatus.Active ?
                    <i className="os-icon os-icon-user-check"></i>
                    : changeUserStatus === undefined ?
                      <i className=""></i>
                      : <i className="os-icon os-icon-user-x"></i>
                  }
                </a>
              )}
              {/* {showStateChange && onStateChange && changeOperatorStatus && (
                <a href="#state-change" onClick={onStateChange}>
                  {changeOperatorStatus === OperatorStatus.Active ?
                    <i className="os-icon os-icon-user-check"></i>
                    : changeOperatorStatus === undefined ?
                      <i className=""></i>
                      : <i className="os-icon os-icon-user-x"></i>
                  }
                </a>
              )} */}
              {showDelete && (
                <a href="#delete" onClick={() => setConfirmingDelete(!confirmingDelete)}>
                  <i className="os-icon os-icon-trash-2"></i>
                </a>
              )}
              {confirmingDelete && (
                <span className="delete-confirm">
                  <button
                    className="btn btn-danger"
                    onClick={() => { setConfirmingDelete(false); onDelete && onDelete(); }}>
                    Confirm Delete
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={() => setConfirmingDelete(false)}>
                    Cancel
                  </button>
                </span>
              )}
              <a href="#show-previous" onClick={onPrev}>
                <i className="os-icon os-icon-arrow-left2"></i>
              </a>
              <a href="#show-next" onClick={onNext}>
                <i className="os-icon os-icon-arrow-right4"></i>
              </a>
            </div>
          </div>
        </div>
      )}
      <div className={classNames('ae-content', childClassName)} >
        {children}
      </div>
    </div>
  )
}
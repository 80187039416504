import React, { useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from '../../../../../components/form/input/input.component';
import { FormSelect } from '../../../../../components/form/input/select.component';
import { FormSection } from '../../../../../components/form/section.component';
import { ViewContentItem } from '../../../../../components/view/item.component';
import { Role } from '../../../../../entities/role.entity';

interface Props {
    roles: Role[],
    isProtected: Role | undefined,
}

export function BasicRolePost({
    roles, isProtected
}: Props) {

    const { t } = useTranslation('forms');
    const childProps = useFormContext();

    return (
        <FormSection>
            {isProtected?.isProtected === true ?
                <div style={{ marginLeft: '10px' }}>
                    <ViewContentItem title={t("role.roleName")}>
                        <div className='textBox'>
                            {isProtected.name}
                        </div>
                    </ViewContentItem>
                </div> :
                <FormInput
                    name="name"
                    label={t("role.roleName")}
                    type="text"
                    className="col-md-6"
                    tabIndex={1}
                    validation={{ required: true }}
                    {...childProps}
                />
            }
            <FormSelect<Role>
                label={t("role.parent")}
                name="parent"
                className="col-md-6"
                validation={{ required: false }}
                items={roles}
                onChange={() => { }}
                tabIndex={2}
                {...childProps}
            />
        </FormSection>
    );
}